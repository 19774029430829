import { Connection } from '@solana/web3.js'
import { getChainRpcUrl } from '../../ethereum/chainProviders'
import { NETWORKS } from '../../../constants/types'
import type { SolanaProvider } from '../../wallets/types/solana.types'

let solanaProvider: SolanaProvider | undefined
let solanaConnection: Connection | undefined

export const setupSolanaProvider = async () => {
  if ('phantom' in window) {
    const provider = window.phantom?.solana

    if (provider?.isPhantom) {
      solanaConnection = new Connection(getChainRpcUrl(NETWORKS.SOLANA))
      solanaProvider = provider
      return provider
    }
  } else if ('solflare' in window) {
    const provider = window.solflare
    if (provider?.isSolflare) {
      solanaConnection = new Connection(getChainRpcUrl(NETWORKS.SOLANA))
      solanaProvider = provider
      return provider
    }
  } else if ('backpack' in window) {
    const provider = window.backpack
    if (provider?.isBackpack) {
      solanaConnection = new Connection(getChainRpcUrl(NETWORKS.SOLANA))
      solanaProvider = provider
      return provider
    }
  }
}

export const getSolanaProvider = (): SolanaProvider => {
  if (!solanaProvider) {
    throw new Error('Please connect your Solana wallet first.')
  }
  return solanaProvider
}

export const getSolanaConnection = (): Connection => {
  if (!solanaConnection) {
    throw new Error('Please connect your Solana wallet first.')
  }
  return solanaConnection
}
