import { Schema as S } from 'effect'
import type { AbiItem } from 'ethereum-multicall/dist/esm/models'
import { rhinoERC1155Abi } from '../../../../services/abis/rhinoERC1155Abi'
import { nftMinterAbi } from '../../../../services/abis/nftMinterAbi'
import { nftRewardZksyncAbi } from '../../../../services/abis/nftRewardZksyncAbi'
import { nftRewardAbi } from '../../../../services/abis/nftRewardAbi'
import { nftMinterZksyncAbi } from '../../../../services/abis/nftMinterZksyncAbi'
import { rhinofiWrapped2023ZksyncNFTAbi } from '../../../../services/abis/rhinofiWrapped2023ZksyncNFTAbi'
import { envConfig } from '../../../../env/envConfig'
import { getWeb3 } from '../../../../services/wallets/wallet'
import type { EVMNFTType, NFTType } from '../../../../services/nfts/mintNFT/mintNFT.types'
import { NETWORKS } from '../../../../constants/types'
import type {
  ERC1155SingleNFTConfig,
  NFTContractType,
  SingleNFTConfig,
} from '../../../../services/nfts/mintNFT/mintNFT.schemas'
import {
  ERC1155SingleNFTConfigSchema,
  NFTConfigSchema,
  NFTContractTypeSchema,
} from '../../../../services/nfts/mintNFT/mintNFT.schemas'
import type { NftContract } from '../../../../env/config.type'

const nftContractsAbis: Record<NFTContractType, AbiItem[]> = {
  [NFTContractTypeSchema.enums.NFTMinter]: nftMinterAbi as AbiItem[],
  [NFTContractTypeSchema.enums.NFTReward]: nftRewardAbi as AbiItem[],
  [NFTContractTypeSchema.enums.RhinoFiWrappedNFT]: rhinofiWrapped2023ZksyncNFTAbi as AbiItem[],
  [NFTContractTypeSchema.enums.RhinoERC1155]: rhinoERC1155Abi as AbiItem[],
}

export const getNFTAbi = (contractType: NFTContractType, chain: string, nftType: NFTType | null): AbiItem[] => {
  // exceptions for old zksync contracts
  if (chain === NETWORKS.ZKSYNC) {
    if (nftType === 'hunter') {
      return nftMinterZksyncAbi as AbiItem[]
    } else if (nftType === 'TOP30') {
      return nftRewardZksyncAbi as AbiItem[]
    }
  }

  return nftContractsAbis[contractType]
}

export const getNFTContractConfig = (chain: string, nftType: NFTType): SingleNFTConfig => {
  const configRaw: unknown = envConfig.nftsContracts
  const config = S.decodeUnknownSync(NFTConfigSchema)(configRaw, { onExcessProperty: 'preserve' })

  if (!config?.[chain]?.[nftType]) {
    throw new Error(`No contract found for ${nftType} on ${chain}`)
  }

  return config[chain][nftType]
}

export const getERC1155ContractConfig = (chain: string, nftType: EVMNFTType): ERC1155SingleNFTConfig => {
  const baseConfig = getNFTContractConfig(chain, nftType)
  return S.decodeUnknownSync(ERC1155SingleNFTConfigSchema)(baseConfig)
}

export const getNFTContract = (type: EVMNFTType, chain: string, useDefaultProvider = true) => {
  const web3PerChain = getWeb3()
  const web3 = useDefaultProvider ? web3PerChain.DEFAULT : web3PerChain[chain]

  try {
    const contractConfig = getNFTContractConfig(chain, type)
    const contractAbi = getNFTAbi(contractConfig.type, chain, type)

    if (!web3 || !contractConfig.address || !contractAbi) {
      return null
    }

    return new web3.eth.Contract(contractAbi, contractConfig.address)
  } catch (error) {
    return null
  }
}

export const hasNFTTypeContract = (type: EVMNFTType, chain: string): boolean => {
  const contracts: Record<string, NftContract> = envConfig.nftsContracts
  return !!contracts?.[chain]?.[type]
}
