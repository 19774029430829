import Aigle from '@rhino.fi/aigle'
import { envConfig } from '../../../../../env/envConfig'
import type { NFTMinter } from './NFTMinterAssets'
import { getNFTMetaData } from './getNFTMetaData'

/**
 * This is unique to zkSync as we had to change the contracts addresses
 * Also the latest version doesn't provide the function to get a user tokenIds
 * So we use chainbase API to get the user NFTs
 */

const zkSyncHunterContracts = [
  '0x6e84bbd27946227f19a07af113c2ff84b288b152', // locked funds
  '0x812de7b8cc9dc7ad5bc929d3337bfb617dcc7949', // latest
]

const zkSyncProHunterContracts = [
  '0xdd01108f870f087b54c28acf1a8bbaf6f6a851ae',
  '0x5a8694ac23114ae97fda5828839888f340c93aa9', // latest
]

const chainbaseApi = envConfig.chainbaseApi
const chainbaseApiKey = envConfig.chainbaseApiKey
const chain = 'ZKSYNC'
const chainId = 324

type ChainBaseResponse = {
  data: Array<{
    contract_address: string
    token_id: string
    name: string
  }>
}

export const getZkSyncNFTs = async (address: string): Promise<NFTMinter[]> => {
  const response = await fetch(`${chainbaseApi}?chain_id=${chainId}&address=${address}`, {
    headers: {
      'x-api-key': chainbaseApiKey,
    },
  })
  const jsonResponse = (await response.json()) as ChainBaseResponse

  if (!jsonResponse.data) {
    return []
  }

  const rhinoNfts = jsonResponse.data.filter(({ contract_address }) =>
    [...zkSyncHunterContracts, ...zkSyncProHunterContracts].includes(contract_address.toLowerCase()),
  )

  const nfts = await Aigle.map(rhinoNfts, async ({ contract_address, token_id }) => {
    const type = zkSyncHunterContracts.includes(contract_address.toLowerCase()) ? 'hunter' : 'TOP30'
    const metadata = await getNFTMetaData(type, chain, Number(token_id))

    return {
      ...metadata,
      tokenId: Number(token_id),
      chain: 'ZKSYNC',
      type,
    }
  })

  return nfts.filter((item): item is NFTMinter => item !== null)
}
