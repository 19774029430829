import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { envConfig } from '../env/envConfig'

import type { Pagination } from '../reducers/types/PaginationState'
import type { AdaptedCandle, RawCandle } from '../reducers/adapters/candlesAdapter'
import { candlesSnapshot } from '../reducers/adapters/candlesAdapter'

import { addAuthorizationHeader } from './auth/addAuthorizationHeader'
import type { PaginationFilters } from './apiService/PaginationFilters.types'
import type { getOrders } from './apiService/getOrders'

const endpointsWithAuth = [
  'getDepositHistory',
  'getCrossChainSwapsHistory',
  'getFills',
  'getWithdrawalsHistory',
  'getTransfersHistory',
  'getFillsHistory',
  'getBridgeHistory',
  'getRegistrationStatus',
]

export type GetDepositsHistoryFilters = {
  token?: string
  status?: string
} & PaginationFilters

export type DepositsHistoryItem = {
  _id: string
  type: string
  token: string
  chain: string
  amount: number | string
  transactionHash?: string
  date: string
  source?: string
  status: string
  depositFee: number | string
}

export type DepositsHistoryState = {
  items: DepositsHistoryItem[]
  pagination: Pagination
}

export type OpenOrders = ReturnType<typeof getOrders>
export const marketDataApi = createApi({
  reducerPath: 'marketDataApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${envConfig.apiUrl}`,
    prepareHeaders: async (headers: Headers, { endpoint }: { endpoint: string }) => {
      headers.set('accept', 'application/json')
      if (endpointsWithAuth.includes(endpoint)) {
        return await addAuthorizationHeader(headers, endpoint)
      }
      return headers
    },
  }),
  tagTypes: ['candles'],
  endpoints: (builder) => ({
    getCandles: builder.query<
      Record<string, AdaptedCandle>,
      {
        symbolKey: string
      }
    >({
      query: (params) => ({ url: `/candles/${params.symbolKey}/last` }),
      transformResponse: (baseQueryReturnValue: RawCandle[], meta, arg) => candlesSnapshot(baseQueryReturnValue),
      providesTags: (_, meta, arg) => [{ type: 'candles', id: arg.symbolKey }],
    }),
  }),
})

export const { useGetCandlesQuery } = marketDataApi
