import type { NationWidgetMissionsFilterItemState } from '../types/nationWidgetMissionsFilterItem.types'

export const NATION_ALL_FILTER_OPTION_DATA: NationWidgetMissionsFilterItemState = {
  key: 'ALL',
  name: { type: 'text', text: '' },
  icon: {
    id: 'check-all',
    type: 'other',
  },
}
