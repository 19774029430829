import { Effect } from 'effect'
import { Client } from 'effect-http'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import type {
  GetAllActiveMissionsResponse,
  GetAllActiveMissionsInputQuery,
  GetActiveMissionsUserDataResponse,
  GetAllGemsTransactionsInputQuery,
  GetAllGemsTransactionsResponse,
  GetGemsBalanceResponse,
  UpdateLastSeenTransactionsResponse,
  UpdateLastSeenTransactionsInput,
  GetAllActivePerksResponse,
  GetUserPerkDataResponse,
  PostPurchasePerkResponse,
  PostPurchasePerkRequestBody,
  GetLeaderboardResponse,
  GetRankResponse,
  GetOneActivePerkByIdResponse,
  GetLeaderboardStatsResponse,
} from '@rhinofi/dvf-rhino-nation-api-spec'
import { apiSpec } from '@rhinofi/dvf-rhino-nation-api-spec'
import { getAuthorizationHeadersEffect } from '../../../services/auth/addAuthorizationHeader'
import { envConfig } from '../../../env/envConfig'
import { runEffectHttpCallback } from '../../../utils/runEffectHttpCallback'

const baseUrl = `${envConfig.tradingApi}/rhino-nation`
const client = Client.make(apiSpec, { baseUrl })

export const nationApi = createApi({
  reducerPath: 'nationApi',
  baseQuery: fetchBaseQuery({}),
  tagTypes: [
    'AllActiveMissions',
    'ActiveMissionsUserData',
    'GemsBalance',
    'AllGemsTransactionsEarnedNotLatest',
    'AllGemsTransactionsSpent',
    'AllActivePerks',
    'UserPerkData',
    'UnusedTag',
    'Authenticated',
    'ActivePerk',
    'UserRank',
  ],
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    getAllActiveMissions: builder.query<GetAllActiveMissionsResponse, GetAllActiveMissionsInputQuery>({
      queryFn: async ({ missionFrequency }) =>
        runEffectHttpCallback(async () =>
          Effect.runPromise(
            client.getAllActiveMissions({
              query: {
                missionFrequency,
              },
            }),
          ),
        ),
      providesTags: ['AllActiveMissions'],
    }),
    getActiveMissionsUserData: builder.query<
      GetActiveMissionsUserDataResponse,
      {
        onSuccess: () => void
      }
    >({
      queryFn: async ({ onSuccess }) =>
        runEffectHttpCallback(async () => {
          const res = await Effect.runPromise(
            client.getActiveMissionsUserData({
              headers: await getAuthorizationHeadersEffect(),
            }),
          )

          onSuccess()

          return res
        }),
      providesTags: ['ActiveMissionsUserData', 'Authenticated'],
    }),
    getGemsBalance: builder.query<GetGemsBalanceResponse, undefined>({
      queryFn: async () =>
        runEffectHttpCallback(async () =>
          Effect.runPromise(
            client.getGemsBalance({
              headers: await getAuthorizationHeadersEffect(),
            }),
          ),
        ),
      // Keep the unused data for longer than other queries due to the GemsBalance being updated by
      // multiple queries/mutations which will often be called close to each other
      // Client endpoints that update 'GemsBalance':
      //   getAllGemsTransactions { type: 'SPENT', latest: false } - Update gems balance due to potential change in number of spent txs
      //   getUserPerkData - Update due to potential change to timesPurchased
      //   getAllGemsTransactions { type: 'EARNED', latest: false } - Update due to potential change in number of earned txs
      //   getActiveMissionsUserData - Update due to potential change to totalTimesAchieved
      //   updateLastSeenTransactions - Update due to potential change in number of latest earned txs
      keepUnusedDataFor: 5,
      providesTags: ['GemsBalance', 'Authenticated'],
    }),
    getAllGemsTransactions: builder.query<
      GetAllGemsTransactionsResponse,
      {
        onSuccess?: () => void
        input: GetAllGemsTransactionsInputQuery
      }
    >({
      queryFn: async ({ onSuccess = () => {}, input: { type, latest } }) =>
        runEffectHttpCallback(async () => {
          const res = await Effect.runPromise(
            client.getAllGemsTransactions({
              query: {
                type,
                latest,
              },
              headers: await getAuthorizationHeadersEffect(),
            }),
          )

          onSuccess()

          return res
        }),
      providesTags: (result, error, arg) => {
        const earnedLatestTag =
          arg.input.type === 'EARNED' && arg.input.latest === false ? 'AllGemsTransactionsEarnedNotLatest' : 'UnusedTag'
        const spentTag = arg.input.type === 'SPENT' ? 'AllGemsTransactionsSpent' : 'UnusedTag'

        return [earnedLatestTag, spentTag, 'Authenticated']
      },
    }),
    updateLastSeenTransactions: builder.mutation<UpdateLastSeenTransactionsResponse, UpdateLastSeenTransactionsInput>({
      queryFn: async ({ cursor }) =>
        runEffectHttpCallback(async () =>
          Effect.runPromise(
            client.updateLastSeenTransactions({
              body: { cursor },
              headers: await getAuthorizationHeadersEffect(),
            }),
          ),
        ),
      invalidatesTags: [
        'AllActiveMissions',
        'ActiveMissionsUserData',
        'GemsBalance',
        'UserRank',
        'AllGemsTransactionsEarnedNotLatest',
      ],
    }),
    getAllActivePerks: builder.query<GetAllActivePerksResponse, undefined>({
      queryFn: async () => runEffectHttpCallback(async () => Effect.runPromise(client.getAllActivePerks({}))),
      providesTags: ['AllActivePerks'],
    }),
    getActivePerk: builder.query<GetOneActivePerkByIdResponse, { perkId: string }>({
      queryFn: async ({ perkId }) =>
        runEffectHttpCallback(async () =>
          Effect.runPromise(
            client.getOneActivePerkById({
              path: { id: perkId },
            }),
          ),
        ),
      providesTags: ['ActivePerk'],
    }),
    getUserPerkData: builder.query<
      GetUserPerkDataResponse,
      {
        onSuccess?: () => void
      }
    >({
      queryFn: async ({ onSuccess = () => {} }) =>
        runEffectHttpCallback(async () => {
          const res = await Effect.runPromise(
            client.getUserPerkData({
              headers: await getAuthorizationHeadersEffect(),
            }),
          )

          onSuccess()

          return res
        }),
      providesTags: ['UserPerkData', 'Authenticated'],
    }),
    postPurchasePerk: builder.mutation<PostPurchasePerkResponse, PostPurchasePerkRequestBody>({
      queryFn: async ({ perkId }) =>
        runEffectHttpCallback(async () =>
          Effect.runPromise(
            client.postPurchasePerk({
              body: { perkId },
              headers: await getAuthorizationHeadersEffect(),
            }),
          ),
        ),
      invalidatesTags: [
        'AllActivePerks',
        'ActivePerk',
        'UserPerkData',
        'GemsBalance',
        'UserRank',
        'AllGemsTransactionsSpent',
      ],
    }),
    getUserRank: builder.query<GetRankResponse, string>({
      queryFn: (user) =>
        runEffectHttpCallback(async () => {
          const { body } = await Effect.runPromise(client.getRank({ path: { user } }))
          return body
        }),
      providesTags: ['UserRank', 'Authenticated'],
    }),
    getLeaderboard: builder.query<GetLeaderboardResponse, undefined>({
      queryFn: () =>
        runEffectHttpCallback(async () => {
          const { body } = await Effect.runPromise(client.getLeaderboard({}))
          return body
        }),
    }),
    getLeaderboardStats: builder.query<GetLeaderboardStatsResponse, undefined>({
      queryFn: () =>
        runEffectHttpCallback(async () => {
          const { body } = await Effect.runPromise(client.getLeaderboardStats({}))
          return body
        }),
    }),
  }),
})

export const {
  useGetGemsBalanceQuery,
  useGetActiveMissionsUserDataQuery,
  useGetAllGemsTransactionsQuery,
  useGetAllActiveMissionsQuery,
  useGetAllActivePerksQuery,
  useGetUserPerkDataQuery,
  useUpdateLastSeenTransactionsMutation,
  usePostPurchasePerkMutation,
  useGetUserRankQuery,
  useGetLeaderboardQuery,
  useGetLeaderboardStatsQuery,
  useGetActivePerkQuery,
} = nationApi
