import { flatten, groupBy, sumBy } from 'lodash'
import { translate } from '../intl/i18n'
import type { FillsState } from '../reducers/types/FillsState'
import { firstInPairPreserveCase, getSymbols, lastInPairPreserveCase } from '../services/formatService'
import * as api from '../services/apiService'
import * as handleError from '../services/apiService/handleError'
import type { AppDispatch } from '../store/configureStore'
import { splitSettledPromises } from '../utils/splitSettledPromises/splitSettledPromises'
import { ExcludeFalsy } from '../utils/ExcludeFalsy'
import { getOrders } from '../services/apiService/getOrders'
import { ordersSlice } from '../reducers/ordersSlice'
import { fetchExchangeBalances } from './balanceActions'
import { addStatusNotification, updateStatusNotification } from './notificationActions/statusNotificationsActions'
import { StatusNotificationStatus } from './types/statusNotifications'

export const fetchOrders = (dispatch: AppDispatch) => async () => {
  try {
    const orders = await getOrders()
    dispatch(ordersSlice.actions.setOpenOrders(orders))
  } catch (error) {
    console.error('Failed fetching orders.')
  }
}

export const fetchOrdersHist = (dispatch: AppDispatch) => async () => {
  try {
    const orders = await api.getOrdersHist()

    dispatch(ordersSlice.actions.setHistOrders(orders))
  } catch (error) {
    console.error('Failed fetching historical orders.')
  }
}

export const fetchSwapsForSymbol = async ({
  symbol,
  limit,
  exchangeSymbols,
}: {
  symbol?: string
  limit: number
  exchangeSymbols: Readonly<string[]>
}) => {
  try {
    const first = firstInPairPreserveCase(symbol)
    const second = lastInPairPreserveCase(symbol)
    const candidateSymbols = [symbol, `${second}:${first}`]

    const validSymbols = candidateSymbols.filter((candidate) =>
      candidate ? exchangeSymbols.includes(candidate) : false,
    )
    const fillsRequests = await Promise.allSettled(
      (validSymbols.length > 0 ? validSymbols : candidateSymbols).map((validSymbol) =>
        api.getFills({ symbol: validSymbol, limit }),
      ),
    )

    const { fulfilled } = splitSettledPromises(fillsRequests)
    const filtered = fulfilled.filter(ExcludeFalsy)
    if (filtered.length === 0) {
      throw new Error(`Failed fetching for symbol ${symbol}`)
    }

    // sum swaps by order Id, average out the prices
    const items = flatten(filtered.map(({ value }) => (value as FillsState).items))
    const grouped = groupBy(items, 'orderId')
    const parsedFills = Object.keys(grouped)
      .map((orderId) => {
        const fills = grouped[orderId]
        const firstFill = fills[0]
        const valueSum = sumBy(fills, (fill) => fill.price * fill.fillAmount)
        const fillAmountSum = sumBy(fills, 'fillAmount') || 1
        const averagePrice = valueSum / fillAmountSum

        // only include if the whole order is accounted for
        if (fillAmountSum !== firstFill.orderAmount) {
          return null
        }

        return { ...firstFill, price: averagePrice }
      })
      .filter(ExcludeFalsy)

    const fills = {
      items: parsedFills,
      pagination: { limit, skip: 0, totalItems: parsedFills.length },
    }

    return fills
  } catch (error) {
    console.error('Failed fetching fills.')
    return { items: [], pagination: { limit, skip: 0, totalItems: 0 } }
  }
}

export const cancelOrder = (dispatch: AppDispatch) => async (orderId: string | number) => {
  const { id } = addStatusNotification(dispatch)({
    title: translate('global.notification_cancelling_order'),
    status: StatusNotificationStatus.pending,
  })
  try {
    const { canceled, active } = await api.cancelOrder(orderId)

    if (canceled) {
      updateStatusNotification(dispatch)(id, { status: StatusNotificationStatus.success })
    } else if (active === false) {
      updateStatusNotification(dispatch)(id, { status: StatusNotificationStatus.error })
    } else {
      dispatch(
        ordersSlice.actions.addAwaitingOrder({
          awaitingOrder: {
            _id: orderId,
            action: 'CANCEL',
          },
        }),
      )
    }

    fetchExchangeBalances(dispatch)()
    fetchOrders(dispatch)()
  } catch (error) {
    updateStatusNotification(dispatch)(id, {
      status: StatusNotificationStatus.error,
      meta: { description: handleError.handleError(error) },
    })
  }
}

export const notifyCancelOrder = (dispatch: AppDispatch) => async () => {
  addStatusNotification(dispatch)({
    title: translate('global.notification_cancelling_order'),
    status: StatusNotificationStatus.success,
  })
}

export const resolveAwaitingOrders = (dispatch: AppDispatch) => (resolvedAwaitingOrders: string[]) => {
  dispatch(ordersSlice.actions.resolveAwaitingOrders(resolvedAwaitingOrders))
}

let timeout: NodeJS.Timeout | null = null
const CLOSE_TIMEOUT = 30 * 1000
export const setOrderPlacingStatus =
  (dispatch: AppDispatch) =>
  (
    orderPlacingStatus:
      | string
      | {
          type: string
          text: string
        },
  ) => {
    if (timeout) {
      clearTimeout(timeout)
    }
    dispatch(ordersSlice.actions.setOrderPlacingStatus(orderPlacingStatus))
    if (orderPlacingStatus === 'success') {
      timeout = setTimeout(() => dispatch(ordersSlice.actions.setOrderPlacingStatus('')), CLOSE_TIMEOUT)
    }
  }

export const fetchMinMaxOrderSize = (dispatch: AppDispatch) => async (symbol: string) => {
  const minMaxOrderSize = await api.getMinMaxOrderSize(symbol)

  const [token1, token2] = getSymbols(symbol)

  const isDAI = token1 === 'DAI'

  dispatch(ordersSlice.actions.setMinMaxOrderSize({ ...minMaxOrderSize, orderSizeSymbol: isDAI ? token2 : token1 }))
}
