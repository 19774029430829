import { disconnect } from 'starknetkit'
import { getStarknetProvider } from '../../providers/starknetProvider'

export const disconnectStarknetWallet = async () => {
  try {
    const starknetProvider = getStarknetProvider()
    if (starknetProvider) {
      await disconnect({ clearLastWallet: true })
    }
  } catch (error) {}
}
