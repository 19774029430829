import { Schema as S } from 'effect'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import type {
  MilestoneCampaignConfigResponse,
  LeaderboardResponse,
  LeaderboardItemWithExpectedReward,
} from '@rhinofi/dvf-campaigns-api-spec'
import { getDvf } from '../../../services/dvfClient'
import { campaignRewardDistributionAbi } from '../../../services/abis/campaignRewardDistributionAbi'
import { envConfig } from '../../../env/envConfig'

const baseUrl = `${envConfig.tradingApi}/campaigns`

export const milestoneCampaignApi = createApi({
  reducerPath: 'milestoneCampaignApi',
  baseQuery: fetchBaseQuery({ baseUrl }),
  tagTypes: ['CampaignConfig', 'Leaderboard', 'Rank'],
  endpoints: (builder) => ({
    getMilestoneCampaignConfig: builder.query<MilestoneCampaignConfigResponse, string>({
      query: (campaignId) => ({ url: `/milestoneCampaignConfig/${campaignId}` }),
      providesTags: ['CampaignConfig'],
    }),
    getLeaderboardWithPagination: builder.query<
      LeaderboardResponse,
      { campaignId: string; page: number; itemsPerPage: number }
    >({
      query: ({ campaignId, page, itemsPerPage }) => {
        const skip = (page - 1) * itemsPerPage
        const limit = itemsPerPage
        return { url: `/milestoneLeaderboard/${campaignId}?skip=${skip}&limit=${limit}` }
      },
      providesTags: ['Leaderboard'],
    }),
    getLeaderboardRank: builder.query<LeaderboardItemWithExpectedReward, { campaignId: string; user: string }>({
      query: ({ campaignId, user }) => ({
        url: `/milestoneLeaderboardRank/${campaignId}/${user}`,
      }),
      providesTags: ['Rank'],
    }),
    hasClaimedCampaignReward: builder.query<
      boolean,
      { contractAddress: string; chain: string; contractId: number; user: string }
    >({
      queryFn: async ({ contractAddress, chain, contractId, user }) => {
        try {
          const dvf = await getDvf()

          const hasClaimedRaw = await dvf.eth.call(
            campaignRewardDistributionAbi,
            contractAddress,
            'claimed',
            [user, contractId],
            {
              chain,
            },
          )
          const hasClaimed = S.decodeUnknownSync(S.Boolean)(hasClaimedRaw)

          return { data: hasClaimed }
        } catch (error) {
          console.error(error)
          return { error: { status: 500, data: 'Failed to fetch' } }
        }
      },
    }),
    claimReward: builder.mutation<
      void,
      {
        contractAddress: string
        chain: string
        campaignId: string
        contractId: number
        user: string
        rewardAmount: string
        onTransactionHash: (txHash: string) => void
      }
    >({
      queryFn: async ({ contractAddress, chain, campaignId, contractId, user, rewardAmount, onTransactionHash }) => {
        try {
          const dvf = await getDvf()

          const signatureResponse = await fetch(`${baseUrl}/milestoneRewardSignature/${campaignId}/${user}`)
          const rawSignature = await signatureResponse.json()
          const { signature } = S.decodeUnknownSync(
            S.Struct({
              signature: S.String,
              user: S.String,
              campaignId: S.Number,
              amount: S.String,
            }),
          )(rawSignature)

          const args = [user, contractId, rewardAmount, signature]

          await dvf.eth.send(campaignRewardDistributionAbi, contractAddress, 'claim', args, 0, {
            chain,
            transactionHashCb: (_: null, txHash: string) => onTransactionHash(txHash),
          })

          return { data: undefined }
        } catch (error) {
          console.error(error)
          return { error: { status: 500, data: 'Failed to claim' } }
        }
      },
    }),
  }),
})

export const {
  useGetMilestoneCampaignConfigQuery,
  useGetLeaderboardWithPaginationQuery,
  useGetLeaderboardRankQuery,
  useHasClaimedCampaignRewardQuery,
  useClaimRewardMutation,
} = milestoneCampaignApi
