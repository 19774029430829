import difference from 'lodash/difference'
import type { AppDispatch } from '../store/configureStore'
import type { CrossChainSwapConfigForChain } from '../services/apiService/getCrossChainSwapConfig'
import { getCrossChainSwapConfig } from '../services/apiService/getCrossChainSwapConfig'
import { getCrossChainTokens } from '../services/apiService/getCrossChainTokens'
import { getCrossChainYield } from '../services/apiService/getCrossChainYield'
import type { CrossChainTokenItem } from '../reducers/types/CrossChainTokensState'
import { isFeatureEnabled, isFeatureFlag } from '../services/helperService/isFeatureEnabled'
import type { CrossChainTokenDisplayConfig } from '../reducers/xchainSlice'
import { xchainSlice } from '../reducers/xchainSlice'

export const fetchCrossChainYield = (dispatch: AppDispatch) => async () => {
  const crossChainYieldResponse = await getCrossChainYield()
  const crossChainYield = crossChainYieldResponse
    .filter((opportunity) => opportunity.protocolData.id !== 'stargate-polygon-usdt')
    .map((yieldItem) => {
      const { protocolData, ...yieldData } = yieldItem
      const { id: protocolId } = protocolData

      return {
        id: `${yieldData.chain}-${yieldData.protocol}-${protocolId}`,
        ...yieldData,
      }
    })

  dispatch(
    xchainSlice.actions.setXchainYield({
      crossChainYield,
    }),
  )
}

const acceptedStatuses = ['LISTED', 'DELISTED']

export const initCrossChain = (dispatch: AppDispatch) => async () => {
  try {
    const [crossChainConfig, crossChainTokensList] = await Promise.all([
      getCrossChainSwapConfig(),
      getCrossChainTokens(),
    ])

    // Check backend enabled
    if (crossChainConfig.enabled) {
      // Ensure chain enabled front end
      const configChains = Object.keys(crossChainConfig.configPerChain).filter((chain) => {
        const key = `DEV_XCHAIN_SWAPS_${chain.toUpperCase()}`
        if (isFeatureFlag(key)) {
          return isFeatureEnabled(key)
        }
        return false
      })

      // Remove non enabled chain tokens
      const crossChainTokens = crossChainTokensList.filter((token) => configChains.includes(token.chain))

      const crossChainTokensConfigPerChain = crossChainTokens.reduce(
        (acc, token) => {
          if (!acc[token.chain]) {
            acc[token.chain] = {}
          }

          acc[token.chain][token.token] = Object.freeze(token)
          return acc
        },
        {} as Record<string, Record<string, CrossChainTokenItem>>,
      )

      const allowedTokens = crossChainTokens.filter((config) => acceptedStatuses.includes(config.status))

      const crossChainYieldTokens = allowedTokens.filter((config) => config.isYield)
      const crossChainSwapTokens = difference(allowedTokens, crossChainYieldTokens)

      // Ensure tokens available for chain in config
      const configPerChain = Object.entries(crossChainConfig.configPerChain).reduce(
        (acc, [chain, config]) => {
          const chainHasTokens = !!crossChainTokensConfigPerChain[chain]
          if (chainHasTokens) {
            acc[chain] = config
          }
          return acc
        },
        {} as Record<string, CrossChainSwapConfigForChain>,
      )

      const crossChainSwapConfig = {
        ...crossChainConfig,
        configPerChain,
      }
      // dispatch token reg and config
      dispatch(
        xchainSlice.actions.setXchainConfig({
          crossChainSwapConfig,
          crossChainTokenRegistry: crossChainSwapTokens,
          crossChainTokenConfig: crossChainSwapTokens.reduce(
            (acc, token) => {
              acc[token.token] = {
                token: token.token,
                chain: token.chain,
                name: token.name,
                iconUrl: token.iconUrl,
                status: token.status,
              }
              return acc
            },
            {} as Record<string, CrossChainTokenDisplayConfig>,
          ),
          crossChainYieldRegistry: crossChainYieldTokens,
          crossChainYieldConfig: crossChainYieldTokens.reduce(
            (acc, token) => {
              acc[token.token] = {
                token: token.token,
                chain: token.chain,
                name: token.name,
                iconUrl: token.iconUrl,
                status: token.status,
              }
              return acc
            },
            {} as Record<string, CrossChainTokenDisplayConfig>,
          ),
          crossChainTokensConfigPerChain,
        }),
      )
    }
  } catch (error) {
    dispatch(
      xchainSlice.actions.setXchainConfig({
        crossChainSwapConfig: {
          enabled: false,
          configPerChain: {},
        },
        crossChainTokenRegistry: [],
        crossChainTokenConfig: {},
        crossChainYieldRegistry: [],
        crossChainYieldConfig: {},
        crossChainTokensConfigPerChain: {},
      }),
    )
  }
}
