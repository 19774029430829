import { Schema as S, Effect } from 'effect'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import type {
  GetAccessInput,
  GetAccessResponse,
} from '@rhinofi/dvf-gated-features-api-spec/dist/apiSpec/access/endpoints'
import { apiSpec } from '@rhinofi/dvf-gated-features-api-spec'
import { Client } from 'effect-http'
import type {
  PostWaitlistInput,
  PostWaitlistResponse,
  GetWaitlistInput,
  GetWaitlistResponse,
} from '@rhinofi/dvf-gated-features-api-spec/dist/apiSpec/waitlist/endpoints'

import { NETWORKS } from '../../../constants/types'
import { envConfig } from '../../../env/envConfig'
import { runEffectHttpCallback } from '../../../utils/runEffectHttpCallback'
import { getDvf } from '../../../services/dvfClient'
import { TokenInfoSchema } from '../../../schema/TokenInfoSchema'
import {
  getERC1155ContractConfig,
  getNFTAbi,
} from '../../../components/ActivityTracker/ActivityTrackerNFT/helpers/nftContractHelpers'

const client = Client.make(apiSpec, { baseUrl: `${envConfig.tradingApi}/gated-features` })

export const gatedFeaturesApi = createApi({
  reducerPath: 'gatedFeatures',
  baseQuery: fetchBaseQuery({}),
  tagTypes: ['Access', 'WaitlistRank'],
  endpoints: (builder) => ({
    getBetaKeyCounter: builder.query<{ maxCap: string; minted: string }, void>({
      queryFn: async () => {
        try {
          const chain = NETWORKS.SCROLL
          const dvf = await getDvf()
          const contractConfig = getERC1155ContractConfig(chain, 'BETAKEY')
          const { address: contractAddress, tokenId: contractTokenId } = contractConfig
          const contractAbi = getNFTAbi(contractConfig.type, chain, 'BETAKEY')

          const tokenInfoRaw = await dvf.eth.call(contractAbi, contractAddress, 'tokens', [contractTokenId], { chain })
          const { maxCap } = S.decodeUnknownSync(TokenInfoSchema)(tokenInfoRaw)

          const totalSupplyRaw = await dvf.eth.call(contractAbi, contractAddress, 'totalSupply', [contractTokenId], {
            chain,
          })
          const totalSupply = S.decodeUnknownSync(S.String)(totalSupplyRaw)

          return {
            data: {
              maxCap: maxCap.toString(),
              minted: totalSupply,
            },
          }
        } catch (error) {
          console.error(error)
          return { error: { status: 500, data: 'Failed to fetch' } }
        }
      },
    }),
    hasAccessToFeature: builder.query<GetAccessResponse, GetAccessInput>({
      queryFn: async (path) => runEffectHttpCallback(async () => Effect.runPromise(client.access({ path }))),
      providesTags: ['Access'],
    }),
    getRankOnWaitlist: builder.query<GetWaitlistResponse, GetWaitlistInput>({
      queryFn: async (path) => runEffectHttpCallback(async () => Effect.runPromise(client.getWaitlist({ path }))),
      providesTags: ['WaitlistRank'],
    }),
    joinWaitlist: builder.mutation<PostWaitlistResponse, PostWaitlistInput>({
      queryFn: async (path) =>
        runEffectHttpCallback(async () => Effect.runPromise(client.postWaitlist({ path })), true),
      invalidatesTags: ['WaitlistRank'],
    }),
  }),
})

export const {
  useGetBetaKeyCounterQuery,
  useHasAccessToFeatureQuery,
  useGetRankOnWaitlistQuery,
  useJoinWaitlistMutation,
} = gatedFeaturesApi
