import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

import type { Wallet } from '../services/wallets/wallet'
import type { TradingKey } from '../services/tradingKeyService/tradingKey.types'
import type { NameServices } from '../services/nameServices/nameServices'

import {
  isWalletConnectedAction,
  isWalletDisconnectAction,
  isWalletErrorAction,
} from '../actions/userActions/connectWalletActions'
import { isAddressChangeAction } from '../actions/userActions/addressChangeActions'

export enum L1SignatureRegistrationStatus {
  'UNKNOWN' = 'UNKNOWN',
  'REQUESTED' = 'REQUESTED',
  'GENERATED' = 'GENERATED',
  'FAILED' = 'FAILED',
}

export type WalletState = {
  data: Wallet | null
  isConnecting: boolean
  isRegistered: boolean
  starkPublicKey: string
  error: string
  name: string | undefined
}
export type AuthenticationState = {
  isAuthenticated: boolean
  isAuthenticating: boolean
}

export type UserState = {
  wallet: WalletState
  authentication: AuthenticationState
  l1RegistrationSignatureStatus?: L1SignatureRegistrationStatus
  tradingKey: {
    data: TradingKey | null
    isFetching: boolean
  }
  network: number | null
  nsNames: {
    [k in NameServices]?: string
  }
  contractWallet: {
    isContractWallet: boolean
    signingAddress: string
  }
}

const initialTradingKey = {
  privateKey: null,
  version: undefined,
  requestLegacyMigration: false,
  needsToImportTradingKey: false,
}

const initialState: UserState = {
  wallet: {
    data: null,
    isConnecting: false,
    isRegistered: false,
    starkPublicKey: '',
    error: '',
    name: undefined,
  },
  authentication: {
    isAuthenticated: false,
    isAuthenticating: false,
  },
  tradingKey: {
    data: initialTradingKey,
    isFetching: false,
  },
  network: null,
  nsNames: {},
  contractWallet: { isContractWallet: false, signingAddress: '' },
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setContractWallet: (
      state,
      action: PayloadAction<{
        isContractWallet: boolean
        signingAddress: string
      }>,
    ) => {
      state.contractWallet = {
        isContractWallet: action.payload.isContractWallet,
        signingAddress: action.payload.signingAddress,
      }
    },
    setConnectingState: (state, action: PayloadAction<{ isConnecting: boolean; error?: string }>) => {
      state.wallet.isConnecting = action.payload.isConnecting
      state.wallet.error = action.payload.error || ''
    },
    setAuthenticating: (state, action: PayloadAction<boolean>) => {
      state.authentication.isAuthenticating = action.payload
    },
    setAuthenticated: (
      state,
      action: PayloadAction<{ isRegistered: boolean; starkPublicKey: string; wallet: Wallet }>,
    ) => {
      return {
        ...state,
        authentication: {
          isAuthenticated: true,
          isAuthenticating: false,
        },
        wallet: {
          ...state.wallet,
          starkPublicKey: action.payload.starkPublicKey,
          isRegistered: action.payload.isRegistered,
        },
      }
    },
    setRegistered: (state, action: PayloadAction<{ isRegistered: boolean; starkPublicKey: string }>) => {
      state.wallet.isRegistered = action.payload.isRegistered
      state.wallet.starkPublicKey = action.payload.starkPublicKey
    },
    setTradingKey: (state, action: PayloadAction<TradingKey | null>) => {
      return {
        ...state,
        tradingKey: {
          ...state.tradingKey,
          data: action.payload,
          isFetching: false,
        },
      }
    },
    setFetchingTradingKey: (state, action: PayloadAction<boolean>) => {
      state.tradingKey.isFetching = action.payload
    },
    setNetwork: (state, action: PayloadAction<{ network: number }>) => {
      state.network = action.payload.network
    },
    setNameServiceNames: (state, action: PayloadAction<{ nsNames: { [k in NameServices]?: string } }>) => {
      state.nsNames = action.payload.nsNames
    },
    setL1SignatureRegistrationStatus: (state, action: PayloadAction<L1SignatureRegistrationStatus>) => {
      state.l1RegistrationSignatureStatus = action.payload
    },
  },
  extraReducers(builder) {
    builder.addMatcher(isWalletErrorAction, (state, action) => {
      return {
        ...state,
        wallet: {
          ...state.wallet,
          error: action.payload.error,
        },
      }
    })
    builder.addMatcher(isWalletDisconnectAction, (state) => {
      return {
        ...state,
        tradingKey: {
          ...state.tradingKey,
          data: initialTradingKey,
        },
        wallet: {
          ...state.wallet,
          data: null,
          isRegistered: false,
          starkPublicKey: '',
        },
        authentication: {
          ...state.authentication,
          isAuthenticated: false,
        },
        network: 0,
        nsNames: {},
        contractWallet: { isContractWallet: false, signingAddress: '' },
      }
    })
    builder.addMatcher(isWalletConnectedAction, (state, action) => {
      return {
        ...state,
        wallet: {
          ...state.wallet,
          data: action.payload.wallet,
          isConnecting: false,
          name: action.payload.name,
        },
      }
    })
    builder.addMatcher(isAddressChangeAction, (state, action) => {
      if (!state.wallet.data) {
        return state
      }
      return {
        ...state,
        wallet: {
          ...state.wallet,
          data: {
            ...state.wallet.data,
            address: action.payload.address,
          },
          isRegistered: false,
          starkPublicKey: '',
          isConnecting: false,
        },
        tradingKey: {
          ...state.tradingKey,
          data: { ...initialTradingKey },
        },
        authentication: {
          ...state.authentication,
          isAuthenticated: false,
        },
        contractWallet: { isContractWallet: false, signingAddress: '' },
        nsNames: {},
      }
    })
  },
})
