import { getTonProvider } from '../../providers/tonProvider'

export const disconnectTonWallet = () => {
  try {
    const tonProvider = getTonProvider()
    if (tonProvider) {
      tonProvider?.walletProvider?.disconnect()
    }
  } catch (error) {}
}
