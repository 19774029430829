// The event names must match the one defined in Userflow

export const userflowEvents = {
  accountCreation: 'account_creation',
  connectWallet: 'connect_wallet',
  authenticate: 'authenticate',
  unlockWallet: 'unlock_wallet',
  deposit: 'deposit',
  swap: 'swap',
  crossChainSwap: 'cross_chain_swap',
  yieldInvest: 'yield_invest',
  bridge: 'bridge',
  activityTracker: 'activity_tracker',
} as const

export type UserflowEvent = (typeof userflowEvents)[keyof typeof userflowEvents]
