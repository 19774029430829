import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

export type SupportedCollapsible =
  | 'portfolio-pools'
  | 'portfolio-off'
  | 'portfolio-invest'
  | 'portfolio-locked-tokens'
  | 'portfolio-available-deposited-tokens'
export type SupportedTable =
  | 'bridges-history'
  | 'withdrawals-history'
  | 'bitfinex-support'
  | 'bitfinex-transfer'
  | 'ContractsInteractionsTableNew'
  | 'ContractsInteractionsTableHistory'
  | 'ContractsInteractionsTablePopular'
  | 'ContractsInteractionsTableEarn'
  | 'exploreTokens'
  | 'cross-chain-swaps-history'
  | 'traders-orders-history'
  | 'transfers-history'
  | 'deposits-history'
  | 'deposits-withdrawals-history'
  | 'amm-funding-orders-history'
  | 'ActivityTrackerProjectsBreakdownTable'
  | 'liquidityProvisionStrategies'
  | 'pools'
  | 'liquidStakingYield'
  | 'poolsAssets'
  | 'offRhinoAssets'
  | 'investAssets'
  | 'rhinoLockedAssets'
  | 'rhinoAvailableDepositedAssets'
  | 'NationSpendClaimedTable'
  | 'milestoneLeaderboard'
  | 'NationLeaderboardTable'

export type SettingsState = {
  tablesSorting: Record<SupportedTable, `${string}-asc` | `${string}-desc` | `${string}-none` | '-'>
  collapsibles: Record<SupportedCollapsible, boolean>
  chartInterval: string
  swap: { slippage: number }
  mode: 'LEDGER' | ''
}

export const initialState: SettingsState = {
  mode: '',
  swap: { slippage: 0.5 },
  chartInterval: 'D',
  collapsibles: {
    'portfolio-pools': true,
    'portfolio-off': true,
    'portfolio-invest': true,
    'portfolio-locked-tokens': true,
    'portfolio-available-deposited-tokens': true,
  },
  tablesSorting: {
    'bridges-history': 'date-desc',
    'withdrawals-history': '-',
    'bitfinex-support': '-',
    'bitfinex-transfer': '-',
    ContractsInteractionsTableNew: '-',
    ContractsInteractionsTableHistory: '-',
    ContractsInteractionsTablePopular: '-',
    ContractsInteractionsTableEarn: '-',
    exploreTokens: '-',
    'cross-chain-swaps-history': '-',
    'traders-orders-history': '-',
    'transfers-history': '-',
    'deposits-history': '-',
    'deposits-withdrawals-history': '-',
    'amm-funding-orders-history': '-',
    ActivityTrackerProjectsBreakdownTable: '-',
    liquidityProvisionStrategies: '-',
    pools: '-',
    liquidStakingYield: '-',
    poolsAssets: '-',
    offRhinoAssets: '-',
    investAssets: '-',
    rhinoLockedAssets: '-',
    rhinoAvailableDepositedAssets: '-',
    NationSpendClaimedTable: '-',
    milestoneLeaderboard: '-',
    NationLeaderboardTable: '-',
  },
}

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setMode(state, action: PayloadAction<{ mode: 'LEDGER' | '' }>) {
      state.mode = action.payload.mode
    },
    setSlippageSettings(state, action: PayloadAction<{ slippage: number }>) {
      state.swap.slippage = action.payload.slippage
    },
    setChartInterval(state, action: PayloadAction<string>) {
      state.chartInterval = action.payload
    },
    setCollapsible(
      state,
      action: PayloadAction<{
        collapsible: SupportedCollapsible
        value?: boolean
      }>,
    ) {
      const { collapsible, value } = action.payload
      state.collapsibles[collapsible] = value === undefined ? !state.collapsibles[collapsible] : value
    },
    setTableOrder(
      state,
      action: PayloadAction<{
        table: SupportedTable
        column: string
        direction?: 'asc' | 'desc' | 'none'
      }>,
    ) {
      const { direction, table, column } = action.payload
      // if direction is not provided, toggle the current direction
      if (!direction) {
        const currentOrder = state.tablesSorting[table]
        if (currentOrder) {
          const [currentColumn, currentDirection] = currentOrder.split('-')
          if (currentColumn === column) {
            state.tablesSorting[table] = `${column}-${currentDirection === 'asc' ? 'none' : 'asc'}`
            return
          }
        }
        state.tablesSorting[table] = `${column}-desc`
        return
      }

      state.tablesSorting[table] = `${column}-${direction}`
    },
  },
})
