import Aigle from '@rhino.fi/aigle'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getNFTsForChain } from '../components/Portfolio/PortfolioAssets/NFTAssets/NFTMinterAssets/getNFTsForChain'
import type { NFTMinter } from '../components/Portfolio/PortfolioAssets/NFTAssets/NFTMinterAssets/NFTMinterAssets'
import { getEnabledNFTMinters } from '../components/ActivityTracker/ActivityTrackerNFT/helpers/isNFTMinterEnabledForChain'
import { NETWORKS } from '../constants/types'
import { isEVMNFTType, type NFTType } from './nfts/mintNFT/mintNFT.types'
import { canMintNFT } from './nfts/canMintNFT'

export const mintedNFTsApi = createApi({
  reducerPath: 'mintedNFTsApi',
  baseQuery: fetchBaseQuery({}),
  tagTypes: ['MintedNFTs', 'StarknetMintedNFTs'],
  endpoints: (builder) => ({
    getMintedNFTs: builder.query<NFTMinter[], { address: string }>({
      queryFn: async ({ address }) => {
        try {
          const nfts = await Aigle.map(getEnabledNFTMinters(), (chain) => getNFTsForChain(chain, address))

          return {
            data: nfts.flat(),
          }
        } catch (error) {
          console.error(error)
          return { error: { status: 500, data: 'Failed to fetch user NFTs' } }
        }
      },
      providesTags: ['MintedNFTs'],
    }),
    getStarknetMintedNFTs: builder.query<NFTMinter[], { secondaryAddress: string }>({
      queryFn: async ({ secondaryAddress }) => {
        try {
          const nfts = await getNFTsForChain(NETWORKS.STARKNET, secondaryAddress)

          return {
            data: nfts.flat(),
          }
        } catch (error) {
          console.error(error)
          return { error: { status: 500, data: 'Failed to fetch user NFTs' } }
        }
      },
      providesTags: ['StarknetMintedNFTs'],
    }),
    canMintNFT: builder.query<boolean, { chain: string; nftType: NFTType; user: string; alreadyMinted: boolean }>({
      queryFn: async ({ chain, nftType, user, alreadyMinted }) => {
        if (!isEVMNFTType(nftType)) {
          return { data: !alreadyMinted }
        }

        const canMint = await canMintNFT({ chain, nftType, user })
        return { data: canMint }
      },
    }),
  }),
})

export const { useGetMintedNFTsQuery, useGetStarknetMintedNFTsQuery, useCanMintNFTQuery } = mintedNFTsApi
