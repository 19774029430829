import type Web3 from 'web3'
import { isEmpty } from 'lodash'
import { translate } from '../../intl/i18n'
import type { SupportedWallets } from '../../constants/types'
import { setDvf } from '../dvfClient'
import type { TradingKey } from '../tradingKeyService/tradingKey.types'
import { sendWithCustomGasPrice } from '../ethereumService'
import { setUpWeb3 } from '../web3/web3Service'
import type { AppDispatch } from '../../store/store.types'
import type { EIP1193Provider } from '../eip6963/EthereumProvider.types'
import { WebWallet } from './connectors/webWallet'
import { LedgerWallet } from './connectors/ledgerWallet'
import { KeystoreWallet } from './connectors/keystoreWallet'
import type { UnlockedKeystoreWallet } from './keystoreService'
import type { Provider } from './walletService'
import { WalletFlows } from './walletFlows'

export type Web3PerChain = Record<string, Web3>
let web3: Web3PerChain | undefined = {}
let provider: Provider | null = null

export type Wallet = {
  address: string
  name: string
  walletType: SupportedWallets
  path?: string
  privateKey?: string
  isLegacy?: boolean
}

export type WalletPayload =
  | (Wallet & {
      tradingKey?: (TradingKey & { encryptedTradingKey?: string }) | null
      starkPublicKey: string
    })
  | null

export type WalletConfig = {
  path?: string
  keystore?: UnlockedKeystoreWallet
  isAutoConnect?: boolean
  name?: string
  provider?: EIP1193Provider
}

export const connectWallet = async ({
  walletType,
  config,
  dispatch,
}: {
  walletType: SupportedWallets
  config?: WalletConfig
  dispatch: AppDispatch
}): Promise<Wallet | null> => {
  const { path = '', keystore } = config || {}
  if (WalletFlows.EVMWALLET.includes(walletType)) {
    const wallet = new WebWallet(walletType, config?.name)
    ;({ web3, provider } = await wallet.connect(config?.provider))
    const dvf = await setDvf({ web3, send: sendWithCustomGasPrice })
    dvf.set('account', wallet.address)
    return wallet.toJSON()
  } else if (WalletFlows.LEDGER.includes(walletType)) {
    const wallet = new LedgerWallet(path, walletType)
    ;({ web3, provider } = await wallet.connect({ dispatch }))
    const dvf = await setDvf({
      web3,
      wallet: {
        type: 'ledger',
        meta: {
          path,
        },
      },
    })
    dvf.set('account', wallet.address)

    return wallet.toJSON()
  } else if (WalletFlows.KEYSTORE.includes(walletType)) {
    if (!keystore) {
      throw new Error(translate('errors.no_keystore'))
    }
    const wallet = new KeystoreWallet(keystore)
    ;({ web3, provider } = await wallet.connect({ dispatch }))
    const dvf = await setDvf({ web3 })
    dvf.set('account', wallet.address)
    return wallet.toJSON()
  } else {
    return null
  }
}

export const getWeb3 = () => {
  if (!web3) {
    throw new Error('Please connect your wallet first.')
  }
  return web3
}

export function getProvider(): Provider
export function getProvider(throwOnNoProvider?: boolean): Provider | null
export function getProvider(throwOnNoProvider = true) {
  if (!provider && throwOnNoProvider) {
    throw new Error('Please connect your wallet first.')
  }
  return provider
}

export const initDefaultWeb3 = async () => {
  // prevent race condition has setUpWeb3 will be called when automatically
  // connecting the wallet and we don't want this call to override the other
  const defaultWeb3 = await setUpWeb3({})
  if (isEmpty(web3)) {
    web3 = defaultWeb3
  }
  return web3
}

export const updateWeb3ForChain = (chain: string, web3ForChain: Web3) => {
  if (web3) {
    web3[chain] = web3ForChain
  }
}
