import type { FFlag } from '../constants/types'
import { MilestoneCampaignID } from '../pages/MilestoneCampaign/types/milestoneCampaign.types'
export const configEnv = 'stg'

export const VITE_HEAP_ANALYTICS_ID = '2345095902'

export const wssConfig = {
  wssUrl: 'wss://api.stg.rhino.fi/market-data/ws',
  wssUrlV2: 'wss://api.stg.rhino.fi/v2/market-data/ws',
}

export const apiUrl = 'https://api.stg.rhino.fi/market-data'
export const marketDataV2V1CompatUrl = 'https://api.stg.rhino.fi/market-data'
export const marketDataV2Url = 'https://api.stg.rhino.fi/v2/market-data'

export const dataApi = 'https://api.stg.rhino.fi/v1/pub'

export const tradingApi = 'https://api.stg.rhino.fi'
export const yieldBoostApi = `https://api.stg.rhino.fi/yield-boost`
export const apyTvlApi = `https://api.stg.rhino.fi/apytvl`

export const bfxApi = 'https://api.stg.rhino.fi/bfx'

export const etherscanApiUrl = 'https://api-sepolia.etherscan.io'

export const geoblockingApiUrl = 'https://api.stg.rhino.fi/gl'

/**
 * Chain provider configs
 *
 * NOTE: confirmationBlocks generally double backend blocks
 * due to lag on timed backend checks.  Ideally move out of
 * UI to backend configuration.
 */
export const chainProviders = {
  ETHEREUM: {
    displayName: 'Ethereum',
    networkName: 'Sepolia Test Network',
    networkId: 11155111,
    nativeTokenSafeguard: 0.008, // ETH
    blockExplorer: 'https://sepolia.etherscan.io',
    rpcUrl: 'https://eth-sepolia.g.alchemy.com/v2/OaRtK4HKXGIRFx3oXh4I9COrV3OMV8VK',
    confirmationBlocks: 5,
    avgBlockTime: 12,
  },
  MATIC_POS: {
    displayName: 'Polygon',
    networkName: 'Amoy (Matic Test Network)',
    networkId: 80002,
    nativeTokenSafeguard: 0.03, // MATIC
    blockExplorer: 'https://www.oklink.com/amoy',
    rpcUrl: 'https://rpc-amoy.polygon.technology',
    gasFeed: 'https://gasstation-testnet.polygon.technology/amoy/v1',
    website: 'https://polygon.technology/polygon-pos',
    confirmationBlocks: 28,
    avgBlockTime: 2,
  },
  POLYGON_MAINNET: {
    displayName: 'Polygon',
    networkName: 'Matic Network',
    networkId: 137,
    nativeTokenSafeguard: 0.03, // MATIC
    blockExplorer: 'https://polygonscan.com',
    rpcUrl: 'https://rpc.ankr.com/polygon',
    gasFeed: 'https://gasstation.polygon.technology/v1',
    confirmationBlocks: 28,
    avgBlockTime: 2,
  },
  BINANCE: {
    displayName: 'BNB Smart Chain',
    networkName: 'BNB Smart Chain (Testnet)',
    networkId: 97,
    nativeTokenSafeguard: 0.0008, // BNB
    blockExplorer: 'https://testnet.bscscan.com',
    rpcUrl: 'https://data-seed-prebsc-1-s1.binance.org:8545',
    website: 'https://www.bnbchain.org/en/smartChain',
    gasFeed: 10000000000, // 10 GWEI for binance testnet
    confirmationBlocks: 20,
    avgBlockTime: 3,
  },
  BINANCE_MAINNET: {
    displayName: 'BNB Smart Chain',
    networkName: 'BNB Smart Chain',
    networkId: 56,
    nativeTokenSafeguard: 0.0008, // BNB
    blockExplorer: 'https://bscscan.com',
    rpcUrl: 'https://bsc-mainnet.public.blastapi.io',
    gasFeed: 'https://api.bscscan.com/api?module=gastracker&action=gasoracle',
    confirmationBlocks: 20,
    avgBlockTime: 3,
  },
  ARBITRUM_MAINNET: {
    displayName: 'Arbitrum One',
    networkName: 'Arbitrum One',
    networkId: 42161,
    nativeTokenSafeguard: 0.001, // ETH
    blockExplorer: 'https://arbiscan.io',
    rpcUrl: 'https://arb1.arbitrum.io/rpc',
    gasFeed: 200000000, // 0.2 GWEI for arbitrum mainnet
    confirmationBlocks: 70,
    avgBlockTime: 0.3,
  },
  ZKSYNC: {
    displayName: 'zkSync Era',
    networkName: 'zkSync Era (Testnet)',
    networkId: 300,
    nativeTokenSafeguard: 0.001, // ETH
    blockExplorer: 'https://sepolia.explorer.zksync.io',
    rpcUrl: 'https://sepolia.era.zksync.dev',
    website: 'https://zksync.io/',
    gasFeed: 300000000, // 0.3 GWEI for zksync
    confirmationBlocks: 4,
    avgBlockTime: 15,
  },
  ZKEVM: {
    displayName: 'Polygon zkEVM',
    networkName: 'Polygon zkEVM (Cardona)',
    networkId: 2442,
    nativeTokenSafeguard: 0.001, // ETH
    blockExplorer: 'https://cardona-zkevm.polygonscan.com',
    rpcUrl: 'https://rpc.cardona.zkevm-rpc.com',
    gasFeed: 'https://gasstation-testnet.polygon.technology/zkevm/cardona',
    website: 'https://polygon.technology/polygon-zkevm',
    confirmationBlocks: 20,
    avgBlockTime: 3,
  },
  OPTIMISM: {
    displayName: 'Optimism',
    networkName: 'Optimism (Testnet)',
    networkId: 11155420,
    nativeTokenSafeguard: 0.001, // ETH
    blockExplorer: 'https://sepolia-optimistic.etherscan.io',
    rpcUrl: 'https://sepolia.optimism.io',
    website: 'https://optimism.io/',
    gasFeed: 1000000, // 0.001 GWEI for optimism testnet
    confirmationBlocks: 28,
    avgBlockTime: 2,
  },
  BASE: {
    displayName: 'Base',
    networkName: 'Base (Testnet)',
    networkId: 84532,
    nativeTokenSafeguard: 0.001, // ETH
    blockExplorer: 'https://sepolia.basescan.org',
    rpcUrl: 'https://sepolia.base.org',
    website: 'https://base.org/',
    gasFeed: 'WEB3',
    confirmationBlocks: 28,
    avgBlockTime: 2,
  },
  LINEA: {
    displayName: 'Linea',
    networkName: 'Linea (Sepolia)',
    networkId: 59141,
    nativeTokenSafeguard: 0.001, // ETH
    blockExplorer: 'https://sepolia.lineascan.build',
    rpcUrl: 'https://rpc.sepolia.linea.build',
    website: 'https://linea.build/',
    gasFeed: 'WEB3',
    confirmationBlocks: 28,
    avgBlockTime: 2,
  },
  OPBNB: {
    displayName: 'opBNB',
    networkName: 'opBNB (Testnet)',
    networkId: 5611,
    nativeTokenSafeguard: 0.001, // BNB
    blockExplorer: 'https://testnet.opbnbscan.com',
    rpcUrl: 'https://opbnb-testnet-rpc.bnbchain.org',
    website: 'https://opbnb.bnbchain.org/',
    gasFeed: 1000000, // 0.001 GWEI for opBNB testnet
    confirmationBlocks: 60,
    avgBlockTime: 1,
  },
  SCROLL: {
    displayName: 'Scroll',
    networkName: 'Scroll Sepolia (Testnet)',
    networkId: 534351,
    nativeTokenSafeguard: 0.001, // ETH
    blockExplorer: 'https://sepolia.scrollscan.dev',
    rpcUrl: 'https://sepolia-rpc.scroll.io',
    website: 'https://scroll.io/',
    gasFeed: 'WEB3',
    gasMultiplier: 1.5,
    confirmationBlocks: 20,
    avgBlockTime: 3,
  },
  MANTA: {
    displayName: 'Manta Pacific',
    networkName: 'Manta Pacific (Sepolia)',
    networkId: 3441006,
    nativeTokenSafeguard: 0.001, // ETH
    blockExplorer: 'https://pacific-explorer.sepolia-testnet.manta.network',
    rpcUrl: 'https://pacific-rpc.sepolia-testnet.manta.network/http',
    website: 'https://pacific.manta.network/',
    gasFeed: 'WEB3',
    confirmationBlocks: 6,
    avgBlockTime: 10,
  },
  STARKNET: {
    displayName: 'Starknet',
    networkName: 'Starknet',
    networkId: 'SN_SEPOLIA',
    nativeTokenSafeguard: 0.0006, // ETH
    blockExplorer: 'https://sepolia.starkscan.co',
    rpcUrl: 'https://starknet-sepolia.public.blastapi.io',
    website: 'https://starknet.com/',
    gasFeed: '',
    confirmationBlocks: 1,
    avgBlockTime: 30,
  },
  TRON: {
    displayName: 'Tron',
    networkName: 'Tron',
    networkId: 2494104990,
    nativeTokenSafeguard: 0.0001, // ETH
    blockExplorer: 'https://shasta.tronscan.org',
    rpcUrl: 'https://api.shasta.trongrid.io/jsonrpc',
    website: 'https://tron.network/',
    gasFeed: '',
    confirmationBlocks: 2,
    avgBlockTime: 30,
  },
  ZETA: {
    displayName: 'Zeta',
    networkName: 'Zeta (Testnet)',
    networkId: 7001,
    nativeTokenSafeguard: 0.001, // ZETA
    blockExplorer: 'https://zetachain-athens-3.blockscout.com',
    rpcUrl: 'https://zetachain-athens-evm.blockpi.network/v1/rpc/public',
    website: 'https://www.zetachain.com/',
    gasFeed: 'WEB3',
    confirmationBlocks: 1,
    avgBlockTime: 5,
  },
  MANTLE: {
    displayName: 'Mantle',
    networkName: 'Mantle (Sepolia)',
    networkId: 5003,
    nativeTokenSafeguard: 0.5, // MNT
    blockExplorer: 'https://explorer.sepolia.mantle.xyz',
    rpcUrl: 'https://rpc.sepolia.mantle.xyz',
    website: 'https://www.mantle.xyz/',
    gasFeed: 'WEB3',
    confirmationBlocks: 28,
    avgBlockTime: 2,
  },
  FRAME: {
    displayName: 'Frame',
    networkName: 'Frame (Testnet)',
    networkId: 68840142,
    nativeTokenSafeguard: 0.001, // ETH
    blockExplorer: 'https://explorer.testnet.frame.xyz',
    rpcUrl: 'https://rpc.testnet.frame.xyz/http',
    website: 'https://www.frame.xyz/',
    gasFeed: 'WEB3',
    confirmationBlocks: 10,
    avgBlockTime: 6,
  },
  MODE: {
    displayName: 'Mode',
    networkName: 'Mode (Testnet)',
    networkId: 919,
    nativeTokenSafeguard: 0.001, // ETH
    blockExplorer: 'https://sepolia.explorer.mode.network',
    rpcUrl: 'https://sepolia.mode.network',
    website: 'https://www.mode.network',
    gasFeed: 'WEB3',
    confirmationBlocks: 28,
    avgBlockTime: 2,
  },
  RARI: {
    displayName: 'Rari',
    networkName: 'Rari (Testnet)',
    networkId: 1918988905,
    nativeTokenSafeguard: 0.001, // ZETA
    blockExplorer: 'https://explorer.rarichain.org',
    rpcUrl: 'https://testnet.rpc.rarichain.org/http',
    website: 'https://rarichain.org',
    gasFeed: 'WEB3',
    confirmationBlocks: 1,
    avgBlockTime: 100,
  },
  KROMA: {
    displayName: 'Kroma',
    networkName: 'Kroma (Testnet)',
    networkId: 2358,
    nativeTokenSafeguard: 0.001, // ETH
    blockExplorer: 'https://sepolia.kromascan.com',
    rpcUrl: 'https://api.sepolia.kroma.network',
    website: 'https://kroma.network',
    gasFeed: 'WEB3',
    gasMultiplier: 2,
    confirmationBlocks: 28,
    avgBlockTime: 2,
  },
  BLAST: {
    displayName: 'Blast',
    networkName: 'Blast (Testnet)',
    networkId: 168587773,
    nativeTokenSafeguard: 0.001, // ETH
    blockExplorer: 'https://testnet.blastscan.io',
    rpcUrl: 'https://sepolia.blast.io',
    website: 'https://blast.io',
    gasFeed: 'WEB3',
    confirmationBlocks: 28,
    avgBlockTime: 2,
  },
  INEVM: {
    displayName: 'inEVM',
    networkName: 'inEVM (Testnet)',
    networkId: 2424,
    nativeTokenSafeguard: 0.001, // INJ
    blockExplorer: 'https://testnet.explorer.inevm.com',
    rpcUrl: 'https://testnet.rpc.inevm.com/http',
    website: 'https://inevm.com',
    gasFeed: 'WEB3',
    confirmationBlocks: 3,
    avgBlockTime: 20,
  },
  XLAYER: {
    displayName: 'X Layer',
    networkName: 'X Layer (Testnet)',
    networkId: 195,
    nativeTokenSafeguard: 0.001, // OKB
    blockExplorer: 'https://www.okx.com/explorer/xlayer-test',
    rpcUrl: 'https://testrpc.xlayer.tech',
    website: 'https://www.okx.com',
    gasFeed: 'https://testrpc.xlayer.tech/gasstation',
    confirmationBlocks: 3,
    avgBlockTime: 20,
  },
  SOLANA: {
    displayName: 'Solana',
    networkName: 'Solana (Devnet)',
    networkId: 901,
    nativeTokenSafeguard: 0.001, // SOL
    blockExplorer: 'https://solscan.io',
    rpcUrl: 'https://api.devnet.solana.com ',
    website: 'https://solana.com/',
    gasFeed: '',
    confirmationBlocks: 2,
    avgBlockTime: 30,
  },
  TAIKO: {
    displayName: 'Taiko',
    networkName: 'Taiko (Testnet)',
    networkId: 167009,
    nativeTokenSafeguard: 0.001, // ETH
    blockExplorer: 'https://hekla.taikoscan.io',
    rpcUrl: 'https://rpc.hekla.taiko.xyz',
    website: 'https://taiko.xyz/',
    gasFeed: 'WEB3',
    confirmationBlocks: 20,
    avgBlockTime: 3,
  },
  PARADEX: {
    displayName: 'Paradex',
    networkName: 'Paradex (Testnet)',
    networkId: 0,
    nativeTokenSafeguard: 0, // ETH
    blockExplorer: 'https://voyager.testnet.paradex.trade',
    rpcUrl: 'https://pathfinder.api.testnet.paradex.trade/rpc/v0_6',
    website: 'https://www.paradex.trade/',
    gasFeed: '',
    confirmationBlocks: 1,
    avgBlockTime: 30,
  },
  ZIRCUIT: {
    displayName: 'Zircuit',
    networkName: 'Zircuit (Testnet)',
    networkId: 48899,
    nativeTokenSafeguard: 0.0001, // ETH
    blockExplorer: 'https://explorer.zircuit.com',
    rpcUrl: 'https://zircuit1-testnet.p2pify.com',
    website: 'https://www.zircuit.com/',
    gasFeed: 'WEB3',
    confirmationBlocks: 20,
    avgBlockTime: 2,
  },
  CRONOS_ZKEVM: {
    displayName: 'Cronos zkEVM',
    networkName: 'Cronos zkEVM (Testnet)',
    networkId: 282,
    nativeTokenSafeguard: 0.0001, // zkTCRO
    blockExplorer: 'https://explorer.zkevm.cronos.org/testnet',
    rpcUrl: 'https://testnet.zkevm.cronos.org',
    website: 'https://cronos.org/zkevm',
    gasFeed: 'WEB3',
    confirmationBlocks: 2,
    avgBlockTime: 30,
  },
  BERACHAIN: {
    displayName: 'Berachain',
    networkName: 'Berachain (Testnet)',
    networkId: 80084,
    nativeTokenSafeguard: 0.0001, // BERA
    blockExplorer: 'https://bartio.beratrail.io',
    rpcUrl: 'https://bartio.rpc.berachain.com',
    website: 'https://www.berachain.com/',
    gasFeed: 'WEB3',
    confirmationBlocks: 1,
    avgBlockTime: 5,
  },
  TON: {
    displayName: 'Ton',
    networkName: 'Ton',
    networkId: 'TON',
    nativeTokenSafeguard: 0.05, // TON
    blockExplorer: 'https://testnet.tonviewer.com',
    rpcUrl:
      'https://testnet.toncenter.com/api/v2/jsonRPC?api_key=51ca175dfbaea11765f36945c5345f5ad7690244891ca47a48b72971b0a36d9d',
    website: 'https://ton.org',
    gasFeed: '',
    confirmationBlocks: 1,
    avgBlockTime: 30,
  },
  APECHAIN: {
    displayName: 'ApeChain',
    networkName: 'ApeChain (Testnet)',
    networkId: 33111,
    nativeTokenSafeguard: 0.0001, // APE
    blockExplorer: 'https://curtis.apescan.io',
    rpcUrl: 'https://curtis.rpc.caldera.xyz/http',
    website: 'https://apechain.com',
    gasFeed: 'WEB3',
    confirmationBlocks: 1,
    avgBlockTime: 5,
  },
}

export const enabledSources = [
  'ETHEREUM',
  'MATIC_POS',
  'ARBITRUM',
  'BINANCE',
  'ZKSYNC',
  'ZKEVM',
  'OPTIMISM',
  'LINEA',
  'BASE',
  'OPBNB',
  'MANTA',
  'SCROLL',
  'STARKNET',
  'AVALANCHE',
  'TRON',
  'MODE',
  'MANTLE',
  'KROMA',
  'BLAST',
  'XLAYER',
  'SOLANA',
  'TAIKO',
  'PARADEX',
  'CRONOS_ZKEVM',
  'TON',
  'APECHAIN',
  'ZIRCUIT',
]

export const multicallAdditionalContracts = {
  ZKSYNC: '0xc6d932284C7A341f0CdA27eb6A21Cb907185b2B0',
  ZKEVM: '0x74f7f831fd415AaE02f0236C5FDF318F10cb1513',
  OPTIMISM: '0x314C01e758a7911E7339aa4F960c7749E8947775',
  BASE: '0x314C01e758a7911E7339aa4F960c7749E8947775',
  LINEA: '0xC2c8eDe80BB5aeB883077eCe496B0C374549D092',
  OPBNB: '0xCAfA5b8B85FCc8d195d43E2B6f5F83245f5349D7',
  SCROLL: '0xfb671e51109f7fBbFD14401B5edE925e03337e90',
  MANTA: '0x314C01e758a7911E7339aa4F960c7749E8947775',
  ZETA: '0x74f7f831fd415AaE02f0236C5FDF318F10cb1513',
  MANTLE: '0x314C01e758a7911E7339aa4F960c7749E8947775',
  FRAME: '0x314C01e758a7911E7339aa4F960c7749E8947775',
  MODE: '0x314C01e758a7911E7339aa4F960c7749E8947775',
  RARI: '0x314C01e758a7911E7339aa4F960c7749E8947775',
  KROMA: '0x314C01e758a7911E7339aa4F960c7749E8947775',
  BLAST: '0xECf5f40d7881866397fE540796B05F58a1907563',
  INEVM: '0x314C01e758a7911E7339aa4F960c7749E8947775',
  XLAYER: '0x314C01e758a7911E7339aa4F960c7749E8947775',
  MATIC_POS: '0x329F5a8d24503fC00B31b229835b6452A6723ae4',
  TAIKO: '0x314c01e758a7911e7339aa4f960c7749e8947775',
  ZIRCUIT: '0x314c01e758a7911e7339aa4f960c7749e8947775',
  CRONOS_ZKEVM: '0x0C54b79CEa7595F269FAaaE76F4397683f075A51',
  BERACHAIN: '0x314c01e758a7911e7339aa4f960c7749e8947775',
  APECHAIN: '0x314C01e758a7911E7339aa4F960c7749E8947775',
}

export const nonEVMMulticallContracts = {
  STARKNET: '0x05bb12e4665e95125aeb1a59354afcfaa6fc3a3d65191d8701ecb8c28c175852',
}

export const multiCallDoNotAggregate = ['BINANCE']

export const network = 11155111

export const addNetworkParametersPerNetworkId = {
  [chainProviders.MATIC_POS.networkId]: {
    chainName: 'Amoy Testnet (Matic)',
    nativeCurrency: {
      name: 'MATIC Token',
      symbol: 'MATIC',
      decimals: 18,
    },
    rpcUrls: [chainProviders.MATIC_POS.rpcUrl],
    iconUrls: [
      'https://polygon.technology/wp-content/uploads/2021/02/cropped-polygon-ico-32x32.png',
      'https://polygon.technology/media-kit/polygon-logo.svg',
    ],
    blockExplorerUrls: [chainProviders.MATIC_POS.blockExplorer],
  },
  137: {
    chainName: 'Polygon (Matic)',
    nativeCurrency: {
      name: 'MATIC Token',
      symbol: 'MATIC',
      decimals: 18,
    },
    rpcUrls: ['https://rpc-mainnet.matic.network', 'https://rpc-mainnet.maticvigil.com'],
    iconUrls: [
      'https://polygon.technology/wp-content/uploads/2021/02/cropped-polygon-ico-32x32.png',
      'https://polygon.technology/media-kit/polygon-logo.svg',
    ],
    blockExplorerUrls: ['https://polygonscan.com/', 'https://matic-mainnet.chainstacklabs.com/'],
  },
  97: {
    chainName: 'Binance Testnet',
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: [
      'https://bitter-weathered-leaf.bsc-testnet.discover.quiknode.pro/97ad555a09b3947ae4254042f871ae4a00f6e26a/',
      'https://data-seed-prebsc-1-s1.binance.org:8545/',
    ],
    iconUrls: ['https://dex-bin.bnbstatic.com/new/static/images/favicon.ico'],
    blockExplorerUrls: ['https://testnet.bscscan.com'],
  },
  56: {
    chainName: 'BNB Smart Chain',
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: ['https://bsc-mainnet.public.blastapi.io', 'https://bsc-dataseed1.defibit.io'],
    iconUrls: ['https://dex-bin.bnbstatic.com/new/static/images/favicon.ico'],
    blockExplorerUrls: ['https://bscscan.com'],
  },
  42161: {
    chainName: 'Arbitrum Mainnet',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://arb1.arbitrum.io/rpc'],
    iconUrls: ['https://arbiscan.io/images/svg/brands/arbitrum.svg'],
    blockExplorerUrls: ['https://arbiscan.io'],
  },
  300: {
    chainName: 'zkSync Era testnet',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://sepolia.era.zksync.dev'],
    iconUrls: [],
    blockExplorerUrls: ['https://sepolia.explorer.zksync.io'],
  },
  [chainProviders.ZKEVM.networkId]: {
    chainName: 'Polygon zkEVM Cardona',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: [chainProviders.ZKEVM.rpcUrl],
    iconUrls: [],
    blockExplorerUrls: [chainProviders.ZKEVM.blockExplorer],
  },
  11155420: {
    chainName: 'Optimism Testnet',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://sepolia.optimism.io'],
    iconUrls: [],
    blockExplorerUrls: ['https://sepolia-optimistic.etherscan.io'],
  },
  84532: {
    chainName: 'Base Testnet',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://sepolia.base.org'],
    iconUrls: [],
    blockExplorerUrls: ['https://sepolia.basescan.org'],
  },
  [chainProviders.LINEA.networkId]: {
    chainName: 'Linea Sepolia',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: [chainProviders.LINEA.rpcUrl],
    iconUrls: [],
    blockExplorerUrls: [chainProviders.LINEA.blockExplorer],
  },
  5611: {
    chainName: 'opBNB Testnet',
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: ['https://opbnb-testnet-rpc.bnbchain.org'],
    iconUrls: [],
    blockExplorerUrls: ['https://testnet.opbnbscan.com'],
  },
  534351: {
    chainName: 'Scroll Sepolia Testnet',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://sepolia-rpc.scroll.io'],
    iconUrls: [],
    blockExplorerUrls: ['https://sepolia.scrollscan.dev'],
  },
  [chainProviders.MANTA.networkId]: {
    chainName: 'Manta Pacific Sepolia',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: [chainProviders.MANTA.rpcUrl],
    iconUrls: [],
    blockExplorerUrls: [chainProviders.MANTA.blockExplorer],
  },
  [chainProviders.TRON.networkId]: {
    chainName: 'Starknet Shasta',
    nativeCurrency: {
      name: 'TRX',
      symbol: 'TRX',
      decimals: 6,
    },
    rpcUrls: [chainProviders.TRON.rpcUrl],
    iconUrls: [],
    blockExplorerUrls: [chainProviders.TRON.blockExplorer],
  },
  [chainProviders.ZETA.networkId]: {
    chainName: 'Zeta Testnet',
    nativeCurrency: {
      name: 'ZETA',
      symbol: 'ZETA',
      decimals: 18,
    },
    rpcUrls: [chainProviders.ZETA.rpcUrl],
    iconUrls: [],
    blockExplorerUrls: [chainProviders.ZETA.blockExplorer],
  },
  [chainProviders.MANTLE.networkId]: {
    chainName: 'Mantle Sepolia',
    nativeCurrency: {
      name: 'MNT',
      symbol: 'MNT',
      decimals: 18,
    },
    rpcUrls: [chainProviders.MANTLE.rpcUrl],
    iconUrls: [],
    blockExplorerUrls: [chainProviders.MANTLE.blockExplorer],
  },
  [chainProviders.FRAME.networkId]: {
    chainName: 'FRAME Testnet',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: [chainProviders.FRAME.rpcUrl],
    iconUrls: [],
    blockExplorerUrls: [chainProviders.FRAME.blockExplorer],
  },
  [chainProviders.MODE.networkId]: {
    chainName: 'Mode Testnet',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: [chainProviders.MODE.rpcUrl],
    iconUrls: [],
    blockExplorerUrls: [chainProviders.MODE.blockExplorer],
  },
  [chainProviders.RARI.networkId]: {
    chainName: 'Rari Testnet',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: [chainProviders.RARI.rpcUrl],
    iconUrls: [],
    blockExplorerUrls: [chainProviders.RARI.blockExplorer],
  },
  [chainProviders.KROMA.networkId]: {
    chainName: 'Kroma Testnet',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: [chainProviders.KROMA.rpcUrl],
    iconUrls: [],
    blockExplorerUrls: [chainProviders.KROMA.blockExplorer],
  },
  [chainProviders.BLAST.networkId]: {
    chainName: 'Blast Testnet',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: [chainProviders.BLAST.rpcUrl],
    iconUrls: [],
    blockExplorerUrls: [chainProviders.BLAST.blockExplorer],
  },
  [chainProviders.INEVM.networkId]: {
    chainName: 'inEVM Testnet',
    nativeCurrency: {
      name: 'INJ',
      symbol: 'INJ',
      decimals: 18,
    },
    rpcUrls: [chainProviders.INEVM.rpcUrl],
    iconUrls: [],
    blockExplorerUrls: [chainProviders.INEVM.blockExplorer],
  },
  [chainProviders.XLAYER.networkId]: {
    chainName: 'X Layer Testnet',
    nativeCurrency: {
      name: 'OKB',
      symbol: 'OKB',
      decimals: 18,
    },
    rpcUrls: [chainProviders.XLAYER.rpcUrl],
    iconUrls: [],
    blockExplorerUrls: [chainProviders.XLAYER.blockExplorer],
  },
  [chainProviders.SOLANA.networkId]: {
    chainName: 'Solana',
    nativeCurrency: {
      name: 'SOL',
      symbol: 'SOL',
      decimals: 9,
    },
    rpcUrls: [chainProviders.SOLANA.rpcUrl],
    iconUrls: [],
    blockExplorerUrls: [chainProviders.SOLANA.blockExplorer],
  },
  [chainProviders.TAIKO.networkId]: {
    chainName: 'Taiko Testnet',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: [chainProviders.TAIKO.rpcUrl],
    iconUrls: [],
    blockExplorerUrls: [chainProviders.TAIKO.blockExplorer],
  },
  [chainProviders.ZIRCUIT.networkId]: {
    chainName: 'Zircuit Testnet',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: [chainProviders.ZIRCUIT.rpcUrl],
    iconUrls: [],
    blockExplorerUrls: [chainProviders.ZIRCUIT.blockExplorer],
  },
  [chainProviders.CRONOS_ZKEVM.networkId]: {
    chainName: 'Cronos zkEVM Testnet',
    nativeCurrency: {
      name: 'ZKCRO',
      symbol: 'ZKCRO',
      decimals: 18,
    },
    rpcUrls: [chainProviders.CRONOS_ZKEVM.rpcUrl],
    iconUrls: [],
    blockExplorerUrls: [chainProviders.CRONOS_ZKEVM.blockExplorer],
  },
  [chainProviders.BERACHAIN.networkId]: {
    chainName: 'Berachain Testnet',
    nativeCurrency: {
      name: 'BERA',
      symbol: 'BERA',
      decimals: 18,
    },
    rpcUrls: [chainProviders.BERACHAIN.rpcUrl],
    iconUrls: [],
    blockExplorerUrls: [chainProviders.BERACHAIN.blockExplorer],
  },
  [chainProviders.TON.networkId]: {
    chainName: 'Ton Testnet',
    nativeCurrency: {
      name: 'TON',
      symbol: 'TON',
      decimals: 9,
    },
    rpcUrls: [chainProviders.TON.rpcUrl],
    iconUrls: [],
    blockExplorerUrls: [chainProviders.TON.blockExplorer],
  },
  [chainProviders.APECHAIN.networkId]: {
    chainName: 'ApeChain Testnet',
    nativeCurrency: {
      name: 'APE',
      symbol: 'APE',
      decimals: 18,
    },
    rpcUrls: [chainProviders.APECHAIN.rpcUrl],
    iconUrls: [],
    blockExplorerUrls: [chainProviders.APECHAIN.blockExplorer],
  },
}

// Referencing tokens supporting https://eips.ethereum.org/EIPS/eip-2612
// for which implementation can vary slighly (hence the need for a config)
// For now, we need the "version" field for each token which can be different
// depending on the token - although specification doesn't explain underlying logic
// Adding new tokens here will make the UI switch to the "permit" model
// (vs. the legacy separate "approve" transaction) when allowance is not set.
export const tokenPermitConfig = {
  ETHEREUM: {
    DVF: {
      version: '1',
    },
  },
}

export const portisAppId = '5e60d4ce-f0cc-4eaa-a34e-2d5820bd6af5'

export const lockedBalanceContract = '0xa3c63971e5b4cdc76efcb236714e7425df7d05f3'

export const balanceContract = '0x768a8fe924aedf120aecc9a54d3d8a35eff41390'

export const necContract = '0x96da48216d5ed99f8e04f511b255edd743242574'

export const dvfContract = '0x89c3cf486dc553225f9d3566d753a48ea03edb45'

export const restrictedCountries = ['CU', 'PK', 'KP', 'IR', 'SY', 'VE', 'IQ', 'LK', 'TT', 'TN', 'VU', 'YE', 'BA', 'UG']

export const maintenance = false

export const sentryUrl = 'https://dc8f2f3d0d4d4161bfb9b3bb7b22b9f4@o417796.ingest.sentry.io/5381734'

export const sentryProject = 'dvf-portal-staging'

export const customTickerSections = ['rad']

export const airdrops = {
  'rhino-run': 'USDT',
}

export const appURL = 'app.stg.rhino.fi'

export const cloudFlareWebAnalyticsScript = false

export const heapApiUrl = 'https://heapanalytics.com/api/track'
export const heapAppId = 2345095902

export const FEATURE_FLAGS: Record<FFlag, boolean> = {
  BRIDGE_PORTFOLIO_TAB: false,
  BRIDGE_REWARDS_CAMPAIGN: false,
  BRIDGE_WIDGET_V2_LOGS: false,
  CDETI_YIELD: false,
  CLAIM_MILESTONE_CAMPAIGN: true,
  CLAIM_TAIKO_MILESTONE_CAMPAIGN: true,
  CONTRACTS_INTERACTIONS: true,
  DEV_ALLOW_LP_TOKEN_WITHDRAWALS: false,
  DEV_ALLOW_YIELD_TRADING: false,
  DEV_DELAY_STARTUP: false,
  DEV_ENABLE_SENTRY: true,
  DEV_ENCHANCE_SENTRY: true,
  DEV_HIDDEN_ACTIONS: false,
  DEV_LOG_TRACKING_EVENTS: false,
  DEV_NEW_SWAP_WIDGET_LOGS: false,
  DEV_SHOW_BRIDGE_REPEAT: false,
  DEV_SUNSET_YIELDBTC: true,
  DEV_XCHAIN_SWAPS_ARBITRUM_MAINNET: true,
  DEV_XCHAIN_SWAPS_BINANCE_MAINNET: true,
  DEV_XCHAIN_SWAPS_POLYGON_MAINNET: true,
  DVF_REDEEM: false,
  STARKNET_ARGENT_MOBILE: true,
  TETHER_THEME: true,
  WRAPPED_2023: false,
  ZIRCUIT_DOPE: false,
  NATION_WIDGET: true,
  NATION_FREE_MINT: true,
}

export const userflowTokenId = 'ct_hwizbabsknae7huh46f43nkebi'

export const environment = 'stg'

export const bridgeTokens = ['ETH', 'USDC', 'MATIC', 'USDT']

export const bridgeToRhinoDisabled = ['MANTA']

export const ethereumDisabledTokens = ['BNB']

export const minBridgeWithdrawalWhenNoFees = 25

export const reCaptchaSiteKey = '6LdKnNMjAAAAAGMX_K43sVxlaSOuKJS-qQEPBmxU'

export const arbitrumChargeEndDate = '2023-03-06T00:00:00.000Z'

export const stargateBoostEndDate = '2023-09-01T00:00:00.000Z'
export const yieldUsdEndDate = '2022-05-11T12:00:00.000Z'
export const yieldEthEndDate = '2023-12-28T12:00:00.000Z'
export const yieldBtcEndDate = '2023-08-13T12:00:00.000Z'
export const yieldThresholdUsdBalance = 1

export const zknsApi = 'https://omniapi.zkns.app/domain-resolver'
export const starknetApi = 'https://api.starknet.id'

export const activityTrackers = ['SCROLL', 'LINEA', 'TAIKO', 'BASE', 'ZKEVM']
export const PROFILE_CHAINS = ['all-chains', ...activityTrackers]

export const CHAINS_WITH_DUNE_DATA = ['BASE', 'SCROLL', 'ZKSYNC']

export const REALM_CHAINS = ['SCROLL', 'LINEA', 'TAIKO', 'BASE', 'ZKEVM', 'ZIRCUIT']

export const minimumClaimablePoolAmount = 1

export const projectsRecommendationsUrl = 'https://splendorous-selkie-a951a2.netlify.app/test-json.json'

export const enabledNFTMinters = ['ZKSYNC', 'LINEA', 'SCROLL', 'ZKEVM', 'STARKNET', 'BLAST', 'TAIKO', 'ZIRCUIT', 'BASE']

export const nftsContracts = {
  ZKSYNC: {
    hunter: {
      type: 'NFTMinter',
      address: '0xb5aD07c2A1e3FA7c4B4E9741f585C3b06B3BBd6F',
    },
    TOP30: {
      type: 'NFTReward',
      address: '0x02CeFbcDe203b80864fdeFbB79fC42f8691c777C',
    },
    wrapped2023: {
      type: 'RhinoFiWrappedNFT',
      address: '0x494951b5F995E424F5d7775C902CbcA50AbdCD86',
    },
    VOLUME: {
      type: 'NFTReward',
      address: '0xcD2587deA6Ba1B61E233a182766F1183a6967f47',
    },
    TXCOUNT: {
      type: 'NFTReward',
      address: '0x6Ee159A1525928a276c173ad69b490B730757e9f',
    },
  },
  LINEA: {
    hunter: {
      type: 'NFTMinter',
      address: '0x0600D7B0da8620A4Cf0bDbf58F1B9F4cceEC143f',
    },
    TOP30: {
      type: 'NFTReward',
      address: '0xa77c93dB8Ce0A8Fb3ACE3b0ea33bdD41415E6fA0',
    },
  },
  SCROLL: {
    hunter: {
      type: 'NFTMinter',
      address: '0x0600D7B0da8620A4Cf0bDbf58F1B9F4cceEC143f',
    },
    TOP30: {
      type: 'NFTReward',
      address: '0xa77c93dB8Ce0A8Fb3ACE3b0ea33bdD41415E6fA0',
    },
    ALPHAKEY: {
      type: 'NFTReward',
      address: '0x5F063Ee9AFdb4c297FcE97a37cEF7a074c6Ff5b4',
    },
    BETAKEY: {
      type: 'RhinoERC1155',
      address: '0xbBCd684630C88A5714F71257BB154a9d1682b414',
      tokenId: 1,
      reducedFeesContract: '0x5F063Ee9AFdb4c297FcE97a37cEF7a074c6Ff5b4',
    },
  },
  ZKEVM: {
    hunter: {
      type: 'NFTMinter',
      address: '0x3baF6f6112c204689AA77938B8F3ddf0ed53Fc43',
    },
    TOP30: {
      type: 'NFTReward',
      address: '0xB483bb969Ba130539d1d1Ce59f6eE58790cd3bA0',
    },
    VOLUME: {
      type: 'NFTReward',
      address: '0x9fF2799Fb07C7Ff7f60FC5c42217c8a9120CBd8B',
    },
    TXCOUNT: {
      type: 'NFTReward',
      address: '0xe3cA55955f1B09D6c332D49aCa32D199736dC68F',
    },
  },
  MANTA: {
    hunter: {
      type: 'NFTMinter',
      address: '0x6aA0295Acc8B3a43916c29424a49b0ef0C7ADdCA',
    },
    TOP30: {
      type: 'NFTReward',
      address: '0x3baF6f6112c204689AA77938B8F3ddf0ed53Fc43',
    },
  },
  BLAST: {
    hunter: {
      type: 'NFTMinter',
      address: '0x4Be1136247CDecA3775a7A32Aff6a621c1c149eB',
    },
    TOP30: {
      type: 'NFTReward',
      address: '0x5752d091d99d8A05CD972DE070008de750341251',
    },
  },
  TAIKO: {
    hunter: {
      type: 'NFTMinter',
      address: '0xB483bb969Ba130539d1d1Ce59f6eE58790cd3bA0',
    },
    TOP30: {
      type: 'NFTReward',
      address: '0x215cfcf325FA579eA584928d300Ca44a96fF4dEA',
    },
    VOLUME: {
      type: 'NFTReward',
      address: '0xC73c425150Eadc38b0cB9Ae1C16dB11172bA1f04',
    },
    TXCOUNT: {
      type: 'NFTReward',
      address: '0xC24cd97Ba960Ec83c7E5C96b2683feBb1cFF2931',
    },
  },
  ZIRCUIT: {
    hunter: {
      type: 'NFTMinter',
      address: '0xc0F6246b99b5db0C9d237884d8E55fEa0cAc7Adf',
    },
  },
  BASE: {
    hunter: {
      type: 'RhinoERC1155',
      address: '0xd089dA617B3da93BBA5a179227592A1a5290cB1A',
      tokenId: 1,
    },
    TOP30: {
      type: 'RhinoERC1155',
      address: '0xd089dA617B3da93BBA5a179227592A1a5290cB1A',
      tokenId: 2,
    },
  },
}

export const nftsThresholds = {
  minRanking: 0.7,
  minVolume: 0.2, // ETH
  minTxCount: 10,
}

export const chainbaseApi = 'https://api.chainbase.online/v1/account/nfts'
export const chainbaseApiKey = '2YtDOftRHb56xrsHy38QgImUx4b'

export const turnstileSiteKey = '1x00000000000000000000AA'

export const maxFreeBridgeAmountUsd = 100

export const airdropEligibilityCriteria = {
  distinctMonths: [2, 3, 4],
  txVolume: [50, 100, 150],
  txCount: [4, 10, 25, 30],
}

export const starkExAuthRedirectUrl = 'https://kolkata.goerli.sw-dev.io/'

export const starknetAirdropUnlockDate = '2024-02-20'

export const starknetNFTs = {
  contractAddress: '0x034536ba58fcbc177a07dfc556edd8171b68913f8f3820f22cf00820ee74a9cc',
  mintFee: '340000000000000',
  tokenIds: {
    EXPLORER: 1,
    GOD: 2,
  },
}

export const GM_CONTRACT_FEE_DEPLOY_WEI = 335000000000000 // 0.000335 ETH = $1 USD (2976.13 $ Per Ether)
export const GM_CONTRACT_FEE_GM_WEI = 33500000000000 // 0.0000335 = 0.1$ USD (2976.13 $ Per Ether)
export const CONTRACTS_INTERACTIONS_CHAINS_CONFIG = {
  TAIKO: {
    feeReceiver: '0xC451b0191351ce308FdFd779D73814c910FC5eCB',
  },
  SCROLL: {
    feeReceiver: '0xC451b0191351ce308FdFd779D73814c910FC5eCB',
  },
  ZKSYNC: {
    feeReceiver: '0x4F170ae33F4e8950a969Ca495544f91254eEA64A',
  },
  LINEA: {
    feeReceiver: '0xC451b0191351ce308FdFd779D73814c910FC5eCB',
  },
  ZKEVM: {
    feeReceiver: '0xC451b0191351ce308FdFd779D73814c910FC5eCB',
  },
  BASE: {
    feeReceiver: '0xC451b0191351ce308FdFd779D73814c910FC5eCB',
  },
}

export const ACTIVITY_TRACKER_CHAIN_EXPLORER_CONFIG_MAPPING = {
  ZKSYNC: {
    url: 'https://block-explorer-api.sepolia.zksync.dev/api',
    apiKeys: [],
  },
  SCROLL: {
    url: 'https://api-sepolia.scrollscan.com/api',
    apiKeys: [],
  },
  LINEA: {
    url: 'https://api-testnet.lineascan.build/api',
    apiKeys: [],
  },
  BLAST: {
    url: 'https://api-sepolia.blastscan.io/api',
    apiKeys: [],
  },
  TAIKO: {
    url: 'https://api-testnet.taikoscan.io/api',
    apiKeys: [],
  },
}

export const NATION_WIDGET_PROMO_PERK_ID = '66d840510fafe7f0039720f6'

export const NATION_FREE_MINT_END_TIME = '2024-11-06T10:00:00.000Z'

export const ACTIVITY_TRACKER_PROJECTS_CONTRACTS = {
  RHINOFI: {
    ZKSYNC: [
      '0xb5aD07c2A1e3FA7c4B4E9741f585C3b06B3BBd6F',
      '0x02CeFbcDe203b80864fdeFbB79fC42f8691c777C',
      '0x494951b5F995E424F5d7775C902CbcA50AbdCD86',
      '0xcD2587deA6Ba1B61E233a182766F1183a6967f47',
      '0x6Ee159A1525928a276c173ad69b490B730757e9f',
      '0xA31b3D8641BcB876dA28A375d0f44761633AB8Fe',
    ],
    SCROLL: ['0x0600D7B0da8620A4Cf0bDbf58F1B9F4cceEC143f', '0xa77c93dB8Ce0A8Fb3ACE3b0ea33bdD41415E6fA0'],
    LINEA: ['0x0600D7B0da8620A4Cf0bDbf58F1B9F4cceEC143f', '0xa77c93dB8Ce0A8Fb3ACE3b0ea33bdD41415E6fA0'],
    BLAST: ['0x4Be1136247CDecA3775a7A32Aff6a621c1c149eB', '0x5752d091d99d8A05CD972DE070008de750341251'],
    TAIKO: [
      '0x65f154d1e8cf617aa3cb0983500f27252267ee81',
      '0xB483bb969Ba130539d1d1Ce59f6eE58790cd3bA0',
      '0x215cfcf325FA579eA584928d300Ca44a96fF4dEA',
      '0xC73c425150Eadc38b0cB9Ae1C16dB11172bA1f04',
      '0xC24cd97Ba960Ec83c7E5C96b2683feBb1cFF2931',
    ],
  },
  SYNCSWAP: {
    ZKSYNC: ['0x856DA67bfCD0669624bFAAFc3b728FC870c70305'],
    SCROLL: ['0x856DA67bfCD0669624bFAAFc3b728FC870c70305'],
    LINEA: ['0x856DA67bfCD0669624bFAAFc3b728FC870c70305'],
  },
  MUTEIO: {
    ZKSYNC: ['0x96c2Cf9edbEA24ce659EfBC9a6e3942b7895b5e8', '0x7aB62F39B074cb376c3AA4a37dFd0606b566fbfe'],
  },
  MAVERICK: {
    ZKSYNC: ['0x9085bae210e7a9fC35f2dcE4c9D72213b4Db53d0'],
  },
  ZKNS: {
    ZKSYNC: ['0x213e2cbF26Bb8822C1057c6721d99807f8BC7531'],
  },
  DERIVIO: {
    ZKSYNC: ['0x5f42930D145764794f0a106D9f1e032888558d03'],
  },
  ERALEND: {
    ZKSYNC: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  AMBIENT: {
    SCROLL: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  KYBER: {
    ZKSYNC: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
    SCROLL: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
    LINEA: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  XY: {
    ZKSYNC: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
    SCROLL: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
    LINEA: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
    BLAST: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  ZEBRA: {
    SCROLL: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  IZUMI: {
    SCROLL: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
    TAIKO: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  GRIZZLY: {},
  UNISWAP: {},
  LAYER_BANK: {
    SCROLL: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
    LINEA: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  SKYDROME: {
    SCROLL: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  SNS: {
    SCROLL: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  PANCAKE_SWAP: {
    ZKSYNC: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
    LINEA: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  DMAIL: {
    ZKSYNC: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
    LINEA: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  ECHO_DEX: {
    LINEA: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  HORIZON_DEX: {
    LINEA: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  WOOFI: {
    LINEA: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  LNS: {
    LINEA: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  DYSON: {
    LINEA: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
    BLAST: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  MENDI: {
    LINEA: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  VELOCORE: {
    ZKSYNC: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  SUSHI: {
    SCROLL: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
    LINEA: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  XFAI: {
    LINEA: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  LYVE: {
    LINEA: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  KURAMA: {
    SCROLL: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  PAPYRUS: {
    SCROLL: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  COG: {
    SCROLL: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  AAVE: {
    SCROLL: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  POOL_SHARK: {
    SCROLL: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  TENK: {
    SCROLL: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  ODOS: {
    ZKSYNC: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  FULCROM: {
    ZKSYNC: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  VESYNC: {
    ZKSYNC: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  ORBIT: {
    BLAST: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  SPACEBAR: {
    BLAST: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  PAC: {
    BLAST: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  JUICE: {
    BLAST: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  THRUSTER: {
    BLAST: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  MONOSWAP: {
    BLAST: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  RING: {
    BLAST: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  SYN_FUTURES: {
    BLAST: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  ROGUEX: {
    BLAST: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  ABRACADABRA: {
    BLAST: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  MANGROVE: {
    BLAST: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  CAMBRIA: {
    BLAST: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  FANTASY: {
    BLAST: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  BLAST_NS: {
    BLAST: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  BIG_INT: {
    ZKSYNC: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  HENJIN: {
    TAIKO: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  RITSU: {
    TAIKO: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  IMAGINAIRYNFTS: {
    TAIKO: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  TAIKO_SWAP: {
    TAIKO: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
}

export const BRIDGE_REWARD_START = '2024-05-01T00:00:00.000Z'

export const ALPHA_KEY_END = '2024-07-19T23:59:59.000Z'

export const PERKS_WAITLIST_WAVE = 1

export const dvfSunsetContract = '0x36FA61f55d199c35b4Ad2fCFC111FA90C98baE82'

export const enabledMilestoneCampaigns = [MilestoneCampaignID.SCROLL, MilestoneCampaignID.TAIKO]

export const yieldSunsetSupportArticleLink =
  'https://support.rhino.fi/en/article/sunsetting-yield-opportunities-bcxqg1/?bust=1728481657380'
