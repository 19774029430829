import { keyBy } from 'lodash'
import pick from 'lodash/pick'
import intersection from 'lodash/intersection'
import { createSelector } from 'reselect'
import { getTokenPrice } from '../../services/helperService/getTokenPrice'
import { getTickerForToken } from '../../services/helperService'
import type { RootState } from '../../store/configureStore'
import type {
  UnifiedTokenRegistryItem,
  UnifiedCrossChainTokenItem,
  UnifiedTokenRegistryState,
} from '../../reducers/types/UnifiedTokenRegistryState'
import type { NonL2TokenRegistryItem } from '../../services/apiService/getConfig.types'
import {
  selectExchangeSymbols,
  selectTokenRegistry,
  selectNonL2TokenRegistry,
  selectBridgeConfigPerChain,
} from './portalSelectors'
import { selectCrossChainTokenRegistry } from './xchainSelectors'

export const selectTokenPrices = (state: RootState) => state.data.tokenPrices

export const selectTokenUsdPrice = (token: string) => (state: RootState) => getTokenPrice(state.data.tokenPrices, token)

export const selectTicker = (state: RootState) => state.data.ticker

export const selectPriceAndChangeForToken = createSelector(
  selectTokenPrices,
  selectTicker,
  (state: RootState, token: string) => token,
  (tokenPrices, ticker, token) => {
    const { dailyChangePerc = 0 } = getTickerForToken(ticker, token) || {}
    const price = getTokenPrice(tokenPrices, token)

    return {
      priceChange: dailyChangePerc,
      price,
    }
  },
)

export const selectTickerForToken = createSelector(
  selectTicker,
  (state: RootState, token: string) => token,
  (ticker, token) => getTickerForToken(ticker, token) || { lastPrice: 0, dailyChangePerc: 0 },
)

export const selectExchangeSymbolForToken = createSelector(
  selectExchangeSymbols,
  (state: RootState, token: string) => token,
  (exchangeSymbols, token) => {
    let usdtPair, usdcPair, ethPair
    exchangeSymbols.map((symbol) => {
      if (`${token}:USDT` === symbol) {
        usdtPair = symbol
      } else if (`${token}:USDC` === symbol) {
        usdcPair = symbol
      } else if (`${token}:ETH` === symbol) {
        ethPair = symbol
      }
    })
    return usdtPair || usdcPair || ethPair
  },
)

type BridgeTokenRegistryItem = Omit<NonL2TokenRegistryItem, 'coingeckoId'>
type BridgeTokenRegistry = Record<string, BridgeTokenRegistryItem>

export const selectBridgeTokenRegistry = createSelector(
  selectTokenRegistry,
  selectNonL2TokenRegistry,
  selectBridgeConfigPerChain,
  (tokenRegistry, nonL2TokenRegistry, bridgeConfigPerChain): BridgeTokenRegistry => {
    const bridgeChains = Object.keys(bridgeConfigPerChain)
    const registryKeys = ['decimals', 'quantization', 'tokenAddressPerChain', 'chainOverride']

    return Object.entries({ ...tokenRegistry, ...nonL2TokenRegistry }).reduce((acc, [token, config]) => {
      const tokenChains = Object.keys(config.tokenAddressPerChain)
      if (intersection(bridgeChains, tokenChains).length) {
        const tokenConfig = pick(config, registryKeys)
        acc[token] = tokenConfig as BridgeTokenRegistryItem
      }
      return acc
    }, {} as BridgeTokenRegistry)
  },
)

// Produces a extended token registry with extra "xchain" and "chain" fields for x-chain tokens
export const selectUnifiedTokenRegistry = createSelector(
  selectTokenRegistry,
  selectCrossChainTokenRegistry,
  (tokenRegistry, crossChainTokenRegistry): UnifiedTokenRegistryState => {
    const unifiedTokenRegistry = Object.entries(tokenRegistry).reduce(
      (acc, [token, config]) => {
        acc[token] = {
          ...config,
          xchain: false,
          status: 'LISTED',
        }
        return acc
      },
      {} as Record<string, UnifiedTokenRegistryItem>,
    )

    const unifiedCrossChainTokenRegistry: Record<string, UnifiedCrossChainTokenItem> = keyBy(
      crossChainTokenRegistry.map((tokenConfig) => ({
        ...tokenConfig,
        xchain: true,
      })),
      'token',
    )

    return {
      ...unifiedTokenRegistry,
      ...unifiedCrossChainTokenRegistry,
    }
  },
)

export const selectListedUnifiedTokenRegistry = createSelector(
  selectUnifiedTokenRegistry,
  (tokenRegistry): UnifiedTokenRegistryState =>
    Object.entries(tokenRegistry).reduce((acc, [token, config]) => {
      if (config.status === 'LISTED') {
        return {
          ...acc,
          [token]: config,
        }
      }
      return acc
    }, {}),
)

export const selectFastWithdrawalTokens = createSelector(selectUnifiedTokenRegistry, (tokenRegistry): string[] =>
  Object.keys(tokenRegistry).filter((key) => {
    const tokenConfig = tokenRegistry[key]
    return 'fastWithdrawalRequiredGas' in tokenConfig && tokenConfig.fastWithdrawalRequiredGas
  }),
)
