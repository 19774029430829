import Aigle from '@rhino.fi/aigle'
import cloneDeep from 'lodash/cloneDeep'
import type { ProviderConfig } from '../ethereum/chainProviders'
import { defaultEVMProvider, evmSideChainProviders } from '../ethereum/chainProviders'
import { KeystoreProvider } from '../wallets/keystoreService'
import { LedgerProvider } from '../wallets/ledgerService'
import type { Web3PerChain } from '../wallets/wallet'
import { getProvider } from '../wallets/wallet'

const importWeb3 = async () => (await import(/* webpackPreload: true */ 'web3')).default
const importProviderEngine = async () => (await import(/* webpackPreload: true */ 'web3-provider-engine')).default
const importRpcSubProvider = async () =>
  (
    await import(
      // @ts-expect-error TS(7016): Could not find a declaration file for module 'web3... Remove this comment to see the full error message
      /* webpackPreload: true */ 'web3-provider-engine/subproviders/rpc'
    )
  ).default

export const setUpWeb3 = async ({
  provider,
  isSubprovider,
  nativeSupport = false,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- provider types conflict between libraries
  provider?: any
  isSubprovider?: boolean
  nativeSupport?: boolean
}) => {
  const Web3 = await importWeb3()
  const ProviderEngine = await importProviderEngine()
  const RpcSubProvider = await importRpcSubProvider()

  const { MetamaskSubprovider, SignerSubprovider, EmptyWalletSubprovider } = await import(
    /* webpackPreload: true */ '@0x/subproviders'
  )
  const loadedProvider = getProvider(false)

  if (!provider && loadedProvider && 'host' in loadedProvider && loadedProvider?.host === defaultEVMProvider.rpcUrl) {
    return
  }

  // Some providers will work out-of-the-box with web3, if so, instantiate provider directly
  if (nativeSupport) {
    // ex: metamask
    const web3 = new Web3(provider)
    const web3PerChain: Web3PerChain = {
      DEFAULT: web3,
    }
    // If current provider is not Ethereum, still instanciate it for fetching balances
    if (!web3PerChain.ETHEREUM) {
      web3PerChain.ETHEREUM = await setEthereumWeb3()
    }

    return setSideChainsProviders(web3PerChain)
  }

  const engine = new ProviderEngine({})
  if (provider && isSubprovider) {
    engine.addProvider(cloneDeep(provider))
  } else if (provider && !isSubprovider) {
    provider.isMetaMask ||
    provider.isStatus ||
    provider.isPortis ||
    provider.isToshi ||
    provider.isTrust ||
    provider.isOkxWallet
      ? engine.addProvider(new MetamaskSubprovider(provider))
      : engine.addProvider(new SignerSubprovider(provider))
  } else if (!provider) {
    engine.addProvider(new EmptyWalletSubprovider())
  }

  // Rpc Provider
  engine.addProvider(new RpcSubProvider({ rpcUrl: defaultEVMProvider.rpcUrl }))

  // Starts the provider
  engine.start()
  // Stops the block tracker
  engine.stop()

  // @ts-expect-error TS(2345): Argument of type 'Web3ProviderEngine' is not assig... Remove this comment to see the full error message
  const web3 = new Web3(engine)

  const web3PerChain: Web3PerChain = {
    DEFAULT: web3,
    ETHEREUM: web3,
  }

  // When Ledger or Keystore, we want to have their specific provider instanciated for signing
  // messages and transactions, and HTTP providers for read operations
  if (provider instanceof LedgerProvider || provider instanceof KeystoreProvider) {
    Object.entries(evmSideChainProviders).forEach(([chain, config]) => {
      const { rpcUrl, alternativeNames = [] } = config
      const sidechainEngine = new ProviderEngine({})
      sidechainEngine.addProvider(cloneDeep(provider))

      // Rpc Provider
      sidechainEngine.addProvider(new RpcSubProvider({ rpcUrl }))
      sidechainEngine.start()
      sidechainEngine.stop()
      // @ts-expect-error TS(2345): Argument of type 'Web3ProviderEngine' is not assig... Remove this comment to see the full error message
      web3PerChain[chain] = new Web3(sidechainEngine)

      // Alternative sidechain ref to chain socket
      alternativeNames.forEach((altChain) => {
        web3PerChain[altChain] = web3PerChain[altChain] || web3PerChain[chain]
      })
    })
    return web3PerChain
  } else {
    return setSideChainsProviders(web3PerChain)
  }
}

const setSideChainsProviders = async (web3PerChain: Web3PerChain) => {
  await Aigle.map(Object.entries(evmSideChainProviders), async ([chain, config]) => {
    if (!web3PerChain[chain]) {
      web3PerChain[chain] = await setSideChainWeb3(config)
    }

    const { alternativeNames = [] } = config
    alternativeNames.forEach((altChain) => {
      web3PerChain[altChain] = web3PerChain[altChain] || web3PerChain[chain]
    })
  })

  return web3PerChain
}

export const setEthereumWeb3 = async () => {
  const Web3 = await importWeb3()
  const ProviderEngine = await importProviderEngine()

  const engine = new ProviderEngine({})

  const RpcSubProvider = await importRpcSubProvider()
  engine.addProvider(new RpcSubProvider({ rpcUrl: defaultEVMProvider.rpcUrl }))
  engine.start()
  engine.stop()

  // @ts-expect-error TS(2345): Argument of type 'Web3ProviderEngine' is not assig... Remove this comment to see the full error message
  return new Web3(engine)
}

export const setSideChainWeb3 = async (config: ProviderConfig) => {
  const Web3 = await importWeb3()
  const { rpcUrl = '' } = config

  const httpProvider = new Web3.providers.HttpProvider(rpcUrl, {
    keepAlive: true,
    timeout: 20000,
  })

  return new Web3(httpProvider)
}
