import { Schema as S } from 'effect'
import { getDvf } from '../dvfClient'
import {
  getNFTAbi,
  getNFTContractConfig,
} from '../../components/ActivityTracker/ActivityTrackerNFT/helpers/nftContractHelpers'
import { TokenInfoSchema } from '../../schema/TokenInfoSchema'
import type { EVMNFTType } from './mintNFT/mintNFT.types'
import { ERC1155SingleNFTConfigSchema } from './mintNFT/mintNFT.schemas'

export const canMintNFT = async ({
  chain,
  nftType,
  user,
}: {
  chain: string
  nftType: EVMNFTType
  user: string
}): Promise<boolean> => {
  const dvf = await getDvf()
  const contractConfig = getNFTContractConfig(chain, nftType)
  const contractAbi = getNFTAbi(contractConfig.type, chain, nftType)
  const contractAddress = contractConfig.address

  if (contractConfig.type === 'RhinoERC1155') {
    const { tokenId } = S.decodeUnknownSync(ERC1155SingleNFTConfigSchema)(contractConfig)
    const userMintedTimes = S.decodeUnknownSync(S.NumberFromString)(
      await dvf.eth.call(contractAbi, contractAddress, 'balanceOf', [user, tokenId], { chain }),
    )
    const tokenInfoRaw = await dvf.eth.call(contractAbi, contractAddress, 'tokens', [tokenId], { chain })
    const { maxBalancePerUser } = S.decodeUnknownSync(TokenInfoSchema)(tokenInfoRaw)

    return userMintedTimes < maxBalancePerUser
  }

  const userTokenIdsRaw = await dvf.eth.call(contractAbi, contractAddress, 'getUserTokenIds', [user], { chain })
  const userTokenIds = S.decodeUnknownSync(S.Array(S.NumberFromString))(userTokenIdsRaw)

  const maxCapPerUser = await (async () => {
    try {
      const maxCapRaw = await dvf.eth.call(contractAbi, contractAddress, 'maxMintPerUser', [], { chain })
      return S.decodeUnknownSync(S.NumberFromString)(maxCapRaw)
    } catch (error) {
      return 1
    }
  })()

  return userTokenIds.length < maxCapPerUser
}
