import { combineReducers } from '@reduxjs/toolkit'
import { settingsSlice } from '../reducers/settingsSlice/settingsSlice'
import { reducers } from '../reducers'
import { userSlice } from '../reducers/userSlice'
import { arbitrumChargeApi } from '../services/arbitrumChargeApi'
import { referralApi } from '../services/referralApi'
import { activityTrackersApi } from '../services/activityTrackers/activityTrackers.api'
import { airdropsApi } from '../services/airdropsApi'
import { tradingApi } from '../services/tradingApi'
import { boostApi } from '../services/yieldService/boostApi'
import { yieldApi } from '../services/yieldService/yieldApi'
import { poolsApi } from '../services/poolService'
import { mintedNFTsApi } from '../services/mintedNFTsApi'
import { statsApi } from '../services/statsApi'
import { geoblockingApi } from '../services/geoblockingApi'
import { contractsInteractionsApi } from '../services/contractsInteractions/contractsInteractions.api'
import { xchainSlice } from '../reducers/xchainSlice'
import { yieldSlice } from '../reducers/yieldSlice'
import { marketDataApi } from '../services/marketDataApi'
import { contractInteractionsSlice } from '../reducers/contractsInteractionsSlice'
import { applicationSlice } from '../reducers/applicationSlice'
import { emailSlice } from '../reducers/emailSlice'
import { migrationStampedeSlice } from '../reducers/migrationStampedeSlice'
import { secondaryWalletSlice } from '../services/secondaryWallet/secondaryWalletSlice'
import { portfolioSlice } from '../reducers/portfolioSlice'
import { blockTrackerSlice } from '../services/blockTracker/blockTracker'
import { ordersSlice } from '../reducers/ordersSlice'
import { tokenMetaSlice } from '../reducers/tokenMetaSlice'
import { dataReducers } from '../reducers/dataReducer'
import { portalSlice } from '../reducers/portalSlice'
import { nationApi } from '../pages/Nation/services/nation.api'
import { gatedFeaturesApi } from '../pages/Nation/services/gatedFeatures.api'
import { milestoneCampaignApi } from '../pages/MilestoneCampaign/services/milestoneCampaign.api'
import { nationSlice } from '../pages/Nation/slices/nation.slice'
import { nationWidgetSlice } from '../pages/Nation/slices/nationWidget.slice'
import { highlightsApi } from '../pages/Bridge/services/highlights.api'

export const rootReducer = combineReducers({
  ...reducers,
  data: dataReducers,
  portal: portalSlice.reducer,
  nation: nationSlice.reducer,
  nationWidget: nationWidgetSlice.reducer,
  portfolio: portfolioSlice.reducer,
  secondaryWallet: secondaryWalletSlice.reducer,
  application: applicationSlice.reducer,
  contractsInteractions: contractInteractionsSlice.reducer,
  yield: yieldSlice.reducer,
  xchain: xchainSlice.reducer,
  email: emailSlice.reducer,
  migrationStampede: migrationStampedeSlice.reducer,
  blockTracker: blockTrackerSlice.reducer,
  user: userSlice.reducer,
  orders: ordersSlice.reducer,
  tokenMeta: tokenMetaSlice.reducer,
  settings: settingsSlice.reducer,
  // when adding here also add to src/middleware/clear-rtk-cache.middleware.ts
  [poolsApi.reducerPath]: poolsApi.reducer,
  [referralApi.reducerPath]: referralApi.reducer,
  [activityTrackersApi.reducerPath]: activityTrackersApi.reducer,
  [contractsInteractionsApi.reducerPath]: contractsInteractionsApi.reducer,
  [mintedNFTsApi.reducerPath]: mintedNFTsApi.reducer,
  [nationApi.reducerPath]: nationApi.reducer,
  [gatedFeaturesApi.reducerPath]: gatedFeaturesApi.reducer,
  [milestoneCampaignApi.reducerPath]: milestoneCampaignApi.reducer,
  [airdropsApi.reducerPath]: airdropsApi.reducer,
  [statsApi.reducerPath]: statsApi.reducer,
  [tradingApi.reducerPath]: tradingApi.reducer,
  [marketDataApi.reducerPath]: marketDataApi.reducer,
  [geoblockingApi.reducerPath]: geoblockingApi.reducer,
  [arbitrumChargeApi.reducerPath]: arbitrumChargeApi.reducer,
  [yieldApi.reducerPath]: yieldApi.reducer,
  [boostApi.reducerPath]: boostApi.reducer,
  [highlightsApi.reducerPath]: highlightsApi.reducer,
})

export type InferredRootState = ReturnType<typeof rootReducer>

// After a reducer is typed remove the associated state from the second type.
export type FilledRootState = InferredRootState
