import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { NationState } from '../types/nationSlice.types'
import { isWalletConnectedAction, isWalletDisconnectAction } from '../../../actions/userActions/connectWalletActions'
import { isAddressChangeAction } from '../../../actions/userActions/addressChangeActions'

const getInitialState = (): NationState => ({
  justMintedPassportNFT: false,
})

export const nationSlice = createSlice({
  name: 'nation',
  initialState: getInitialState(),
  reducers: {
    setJustMintedPassportNFT(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        justMintedPassportNFT: action.payload,
      }
    },
  },
  extraReducers: (builder) => {
    const reset = () => getInitialState()
    builder.addMatcher(isWalletConnectedAction, reset)
    builder.addMatcher(isAddressChangeAction, reset)
    builder.addMatcher(isWalletDisconnectAction, reset)
  },
})

export const { setJustMintedPassportNFT } = nationSlice.actions
