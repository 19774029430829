import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import type { Pagination } from '../reducers/types/PaginationState'
import { getAuthenticationData } from './apiService'
import { getDvf } from './dvfClient'

type TokenDelta = {
  token: string
  amount: string
  amountFilled: string
}

export type AmmFundingOrderHistoryItem = {
  _id: string
  pending: boolean
  failed: boolean
  type: 'Deposit' | 'Withdrawal'
  pool: string
  user: string
  createdAt: Date
  updatedAt: Date
  tokenDeltas: TokenDelta[]
}
export type GetAmmOrdersHistoryFilters = {
  startDate?: string
  endDate?: string
  pool?: string
  sortDirection?: string
  skip?: number
  limit?: number
}

export async function getAmmFundingOrders(
  filters: GetAmmOrdersHistoryFilters,
  headers?: { accept: 'text/csv' },
): Promise<string>
export async function getAmmFundingOrders(filters = {}, headers = { accept: 'text/csv' } as const) {
  const dvf = await getDvf()
  const { nonce, signature } = await getAuthenticationData(dvf)
  return dvf.getAmmFundingOrders(nonce, signature, filters, headers)
}

export type AmmFundingOrdersHistoryResponse = {
  items: AmmFundingOrderHistoryItem[]
  pagination: Pagination
}

export const poolsApi = createApi({
  reducerPath: 'poolsApi',
  tagTypes: ['PoolsHistory', 'PoolsTokensRate', 'PoolsTvlHistory', 'PoolsData', 'PoolsUserData'],
  baseQuery: fetchBaseQuery({}),
  endpoints: (builder) => ({
    getAmmFundingOrders: builder.query<AmmFundingOrdersHistoryResponse, GetAmmOrdersHistoryFilters>({
      providesTags: ['PoolsHistory'],
      queryFn: async (filters) => {
        try {
          const dvf = await getDvf()
          const { nonce, signature } = await getAuthenticationData(dvf)
          return {
            data: await dvf.getAmmFundingOrders(nonce, signature, filters),
          }
        } catch (error) {
          console.error(error)
          return { error: { status: 500, data: 'Failed to fetch AMM funding orders' } }
        }
      },
    }),
  }),
})

export const { useGetAmmFundingOrdersQuery } = poolsApi
