import { Schema as S } from 'effect'

export const TokenInfoSchema = S.Struct({
  maxCap: S.NumberFromString,
  maxBalancePerUser: S.NumberFromString,
  isTransferable: S.Boolean,
  mintFee: S.NumberFromString,
  reducedMintFee: S.NumberFromString,
  reducedMintFeeContract: S.String,
  reducedMintFeeTokenID: S.NumberFromString,
  tokenURI: S.String,
})
